import { useTranslation } from "react-i18next";
import {
  AppraiserUser,
  useLazyGetAppraiserQuery,
} from "../../../../../redux/apiSpecifications/apiFesf";
import { ContentWrapper, GenericWrapper } from "../../Commons/Wrappers";
import AppraiserDetailsSubjectAdditionalInfo from "./AppraiserDetailsSubjectAdditionalInfo";
import AppraiserDetailsSubjectPersonalData from "./AppraiserDetailsSubjectPersonalData";
import styled from "styled-components";
import { useEffect } from "react";
import CodiceFiscale from "codice-fiscale-js";
import { useDispatch } from "react-redux";
import { mainTabs } from "../../../../../config/testIds";
import {
  clearPendingChanges,
  setAppraiserError,
  setAppraiserMainData,
  setAppraiserStatus,
  setValidation,
} from "../../../../../redux/features/appraisersSlice";
import AppraiserPaymentMethod from "./AppraiserPaymentMethod";
import { useAuthorization } from "../../../../../hooks/useAuthorization";

const testIds = mainTabs.tabs.supplierNetwork.appraiserDetails.subject;

const AppraisalDetailsSubjectContainer = styled(ContentWrapper)`
  overflow: auto;
  max-height: calc(100vh - 16em);
  padding-right: 2em;
`;

const AppraiserDetailsSubjectWrapper = styled(GenericWrapper)`
  display: flex;
  flex-direction: column;

  .button-wrapper {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2em;
  }
`;

const isEmptyOrNull = (str: string | undefined) =>
  !str || str.replaceAll(" ", "").length == 0;

interface IAppraiserDetailsSubjectProps {
  idAppraiser?: number;
  appraiser: AppraiserUser | undefined;
  onChange: (appraiser: AppraiserUser) => void;
}

const AppraiserDetailsSubject = (props: IAppraiserDetailsSubjectProps) => {
  const { t } = useTranslation();

  const { idAppraiser, appraiser, onChange } = props;

  const dispatch = useDispatch();

  const { getAuthorization } = useAuthorization();

  const [loadAppraiser] = useLazyGetAppraiserQuery();

  useEffect(() => {
    if (idAppraiser !== undefined) {
      dispatch(
        setAppraiserStatus({
          id: idAppraiser,
          status: "loading",
        })
      );

      (async () => {
        const response = await loadAppraiser({
          authorization: await getAuthorization(),
          id: idAppraiser,
        });

        if (response.isSuccess && response.data) {
          dispatch(
            setAppraiserMainData({
              appraiser: response.data as AppraiserUser,
            })
          );

          // clear eventual fiscal code auto set to changed
          // setTimeout(() => {
          //   dispatch(clearPendingChanges(idAppraiser));
          // }, 200);
        } else {
          dispatch(
            setAppraiserError({
              id: idAppraiser,
              message: "Errore caricamento dettagli perito",
            })
          );
        }
      })();
    }
  }, []);

  // useEffect(() => {
  //     if (!appraiser) return;
  //     validateAppraiser(appraiser);
  // }, [appraiser]);

  const handleOnChange = (appraiser: AppraiserUser) => {
    validateAppraiser(appraiser);
    onChange(appraiser);
  };

  const validateAppraiser = (appraiser: AppraiserUser) => {
    let isValid = true;
    if (appraiser.subjectType === "PF") {
      if (isEmptyOrNull(appraiser.name)) isValid = false;
      if (isEmptyOrNull(appraiser.lastname)) isValid = false;
      else {
        try {
          new CodiceFiscale(appraiser.fiscalCode!);
        } catch (e) {
          isValid = false;
        }
      }
    } else if (appraiser.subjectType === "PG") {
      if (isEmptyOrNull(appraiser.businessName)) isValid = false;
      if (isEmptyOrNull(appraiser.giuridicalType)) isValid = false;
    }

    dispatch(
      setValidation({
        id: appraiser.id!,
        validationErrors: {
          subject: isValid ? [] : ["invalida data"],
        },
      })
    );
  };

  return (
    <AppraisalDetailsSubjectContainer>
      <AppraiserDetailsSubjectWrapper data-testid={testIds.container}>
        <AppraiserDetailsSubjectPersonalData
          appraiser={appraiser}
          onChange={handleOnChange}
        />

        <AppraiserPaymentMethod
          appraiser={appraiser}
          onChange={handleOnChange}
        />

        <AppraiserDetailsSubjectAdditionalInfo
          appraiser={appraiser}
          onChange={(a) => handleOnChange(a)}
        />
      </AppraiserDetailsSubjectWrapper>
    </AppraisalDetailsSubjectContainer>
  );
};

export default AppraiserDetailsSubject;
