import { Modal, Tabs, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { IconLoading, IconSearch } from "../../config/icons";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import dekra from "../../style/dekra";
import { addTab } from "../../redux/features/mainTabsSlice";
import SearchFormAppraisals from "./SearchFormAppraisals";
import { clickableSearch as testId } from "../../config/testIds";
import {
  SearchPracticesParams,
  SearchResponseAppraiser,
  SearchResponseBo,
  useSearchPracticesAppraiserMutation,
  useSearchPracticesBoMutation,
} from "../../redux/apiSpecifications/apiCrud";
import { RootState } from "../../redux/store";
import { useAuthorization } from "../../hooks/useAuthorization";
import SearchFormClaims from "./SearchFormClaims";
import { Tab } from "rc-tabs/lib/interface";
import {
  SearchClaimsParams,
  SearchedClaimsList,
  useSearchClaimsMutation,
} from "../../redux/apiSpecifications/apiClaims";

const SearchBoxWrapper = styled.div``;

const IconSearchWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 4em;

  svg {
    color: #fff;

    font-size: 2em;
  }
`;

const TabsWraper = styled(Tabs)`
  .ant-tabs-nav-operations {
    display: none !important;
  }

  .ant-tabs-ink-bar {
    background: ${dekra.primaryColor};
  }

  .ant-tabs-nav-list {
    background-color: #eee;
    color: #333;
    width: 100%;

    .ant-tabs-tab:first-child {
      border-left: none;
    }
  }

  .ant-tabs-tab {
    padding: 0.75em 2em;
    letter-spacing: 2px;
    border-left: 1px solid #ccc;
    text-transform: uppercase;
    margin: 0;
    flex: 1;
    justify-content: center;

    .ant-tabs-tab-btn {
      &:hover {
        color: ${dekra.primaryColor};
      }
    }
  }

  .ant-tabs-tab-active {
    background: ${dekra.primaryColor};

    .ant-tabs-tab-btn {
      color: white;

      &:hover {
        color: white;
      }
    }
  }
`;

export const LoadingWrapper = styled.div`
  height: 400px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  div {
    width: 100px;
  }
`;

const ClickableSearch = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [loadingError, setLoadingError] = useState<string | undefined>();

  const [tabs, setTabs] = useState<Tab[]>([]);

  const [searchPracticesBo] = useSearchPracticesBoMutation();
  const [searchPracticesAppraiser] = useSearchPracticesAppraiserMutation();

  const [searchClaims] = useSearchClaimsMutation();

  const { getAuthorization } = useAuthorization();

  const { activeRole } = useSelector((state: RootState) => state.user);

  const isUserBo = ["D1", "D2"].includes(activeRole || "");
  const isTechnicalRoom = ["E1", "E4"].includes(activeRole || "");
  const isUserAppraiser = ["E2", "E3"].includes(activeRole || "");
  const isSupplierNetwork = ["NF"].includes(activeRole || "");
  const isAdministrative = ["G1", "G2"].includes(activeRole || "");
  const isCustomer = ["F1"].includes(activeRole || "");
  const isAppraisalAnalyst = ["H1"].includes(activeRole || "");

  const isFrontend = ["A1"].includes(activeRole || "");
  const isLiquidator = ["B1"].includes(activeRole || "");
  const isSupervisor = ["C1"].includes(activeRole || "");

  const isAppraisalsScope =
    isUserBo ||
    isTechnicalRoom ||
    isUserAppraiser ||
    isSupplierNetwork ||
    isAdministrative ||
    isCustomer ||
    isAppraisalAnalyst;

  const isClaimsScope = isFrontend || isLiquidator || isSupervisor;

  const toggleOpen = () => setOpen(!open);

  const handleSearchClaims = async (searchParams: SearchClaimsParams) => {
    setLoading(true);
    setLoadingError(undefined);

    try {
      const response = await searchClaims({
        pageNum: 1,
        pageLen: 10,
        scenario: "search",
        activeRole: activeRole!,
        searchClaimsParams: searchParams,
      });

      const searchResponse = response as {
        data: SearchedClaimsList;
      };

      dispatch(
        addTab({
          key: 0,
          label: t("search-results-claims-label"),
          type: "search-results-claims",
          externalData: { ...searchResponse.data },
        })
      );

      toggleOpen();
    } catch (e) {
      console.error(e);

      setLoadingError(t("waiting-for-network-delay-result-error")!);
    }

    setLoading(false);
  };

  const handleSearchAppraisals = async (
    searchParams: SearchPracticesParams
  ) => {
    setLoading(true);
    setLoadingError(undefined);

    try {
      if (
        isUserBo ||
        isTechnicalRoom ||
        isSupplierNetwork ||
        isAdministrative ||
        isCustomer ||
        isAppraisalAnalyst
      ) {
        const response = await searchPracticesBo({
          authorization: await getAuthorization(),
          searchPracticesParams: searchParams,
          activeRole: activeRole!,
        });

        const searchResponse = (
          response as {
            data: SearchResponseBo;
          }
        ).data as SearchResponseBo;

        // const listPractice = (
        //     response as {
        //         data: PracticeList;
        //     }
        // ).data as PracticeList;

        dispatch(
          addTab({
            key: 0,
            label: t("search-results-appraisals-label"),
            type: "search-results-appraisals",
            externalData: {
              items: searchResponse.results,
              totalResultCount: searchResponse.totalResultCount,
            },
          })
        );
      } else if (isUserAppraiser) {
        const response = await searchPracticesAppraiser({
          authorization: await getAuthorization(),
          searchPracticesParams: searchParams,
          activeRole: activeRole!,
        });

        const listPractice = (
          response as {
            data: SearchResponseAppraiser;
          }
        ).data as SearchResponseAppraiser;

        // const listPractice = (
        //     response as {
        //         data: PracticeE2List[];
        //     }
        // ).data as PracticeE2List[];

        dispatch(
          addTab({
            key: 0,
            label: t("search-results-appraisals-label"),
            type: "search-results-appraisals",
            externalData: {
              items: listPractice.results,
              totalResultCount: listPractice.totalResultCount,
            },
          })
        );
      }

      toggleOpen();
    } catch (e) {
      console.error(e);
      setLoadingError(t("waiting-for-network-delay-result-error")!);
    }

    setLoading(false);
  };

  return (
    <>
      <SearchBoxWrapper data-testid={testId.container}>
        <Tooltip title={t("search")}>
          <IconSearchWrapper
            onClick={() => setOpen(true)}
            data-testid={testId.buttonOpen}
          >
            <IconSearch />
          </IconSearchWrapper>
        </Tooltip>
      </SearchBoxWrapper>

      <Modal
        open={open}
        footer={null}
        closable={false}
        onCancel={toggleOpen}
        width="80%"
      >
        {loading && (
          <LoadingWrapper>
            <div>{IconLoading}</div>
          </LoadingWrapper>
        )}

        {loadingError && <></>}

        {!loading && !loadingError && (
          <>
            {isAppraisalsScope ? (
              <TabsWraper
                defaultActiveKey="appraisals"
                items={[
                  {
                    key: "appraisals",
                    label: t("search-appraisals"),
                    children: (
                      <SearchFormAppraisals
                        onSearch={(params) => handleSearchAppraisals(params)}
                        onCancel={toggleOpen}
                      />
                    ),
                  },
                ]}
              />
            ) : (
              <TabsWraper
                defaultActiveKey="claims"
                items={[
                  {
                    key: "claims",
                    label: t("search-claims"),
                    children: (
                      <SearchFormClaims
                        onSearch={(params) => handleSearchClaims(params)}
                        onCancel={toggleOpen}
                      />
                    ),
                  },
                ]}
              />
            )}
          </>
        )}
      </Modal>
    </>
  );
};

export default ClickableSearch;
