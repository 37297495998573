import { useEffect, useState } from "react";

import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { clickableSearch as testIds } from "../../config/testIds";
import { FormRow } from "../../style/form";
import {
  DatePickerStyled,
  GridRowStyled,
  InputTextStyled,
  SwitchStyled,
} from "../../style/Input";
import { ButtonCancel, ButtonConfirm } from "../Layout/Buttons";
import { SearchPracticesParams } from "../../redux/apiSpecifications/apiCrud";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import SelectPracticeStatus from "../Inputs/SelectPracticeStatus";
import {
  AppraisalStatusTypes,
  AppraisalTypeTypes,
  DateFormat,
} from "../../config/const";
import { InputWithBadge } from "../Inputs/InputWithBadge";
import { RowSpacer } from "../../style/containers";
import moment from "moment";
import { formatDate } from "../../utils/date";
import SelectAppraiser from "../Inputs/SelectAppraiser";
import SelectAgreement, { AgreementType } from "../Inputs/SelectAgreement";
import SelectPracticeType from "../Inputs/SelectPracticeType";
import AutocompletePracticeStatus from "../Inputs/AutocompletePracticeStatus";

const SearchWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2em 4em;
  position: relative;
  min-height: 22em;

  .ant-form-item {
    .ant-form-item-row {
      .ant-form-item-label {
        width: 180px !important;
      }
    }
  }

  .ant-picker {
    width: 100%;
  }
`;

const SearchButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1em;

  svg {
    font-size: 1.4em;
    margin-right: 0.5em;
  }

  span {
    letter-spacing: 2px;
    margin-top: 0px;
    font-size: 1.2em;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    margin-top: 2em;
  }
`;

const DateWrapper = styled.div`
  display: flex;
  flex: 1;
`;

const today = moment().startOf("day");
const PRIMA_ASSICURAZIONI = "PRIMA ASSICURAZIONI S.p.A.";

export type SearchWidth = "small" | "default" | "large";

interface ISearchFormClaimsProps {
  onSearch: (params: SearchPracticesParams) => void;
  onCancel?: () => void;
}

const SearchFormAppraisals = (props: ISearchFormClaimsProps) => {
  const { t } = useTranslation();

  const { onSearch, onCancel } = props;

  const { activeRole, selectedTenants } = useSelector(
    (state: RootState) => state.user
  );

  const [searchParams, setSearchParams] = useState({} as SearchPracticesParams);
  const [customerPrima, setCustomerPrima] = useState<boolean>();

  const isUserExternalAppraiser = ["E3"].includes(activeRole || "");

  useEffect(() => {
    setSearchParams({
      ...searchParams,
      tenants: selectedTenants?.map((t) => t.id as number),
    });

    setCustomerPrima(
      selectedTenants?.find(
        (t) => t.id === 100 && t.companyCode === PRIMA_ASSICURAZIONI
      ) !== undefined
    );
  }, [selectedTenants]);

  const handleChangeSearchParams = (val: any, field: string) => {
    setSearchParams((prev) => ({ ...prev, [field]: val }));
  };

  const handleOnSearch = () => {
    const updatedParams = {
      ...searchParams,
      tenants: selectedTenants?.map((t) => t.id as number),
      idAppraiser:
        (searchParams?.idAppraiser || 0) > 0
          ? searchParams.idAppraiser
          : undefined,
    };
    onSearch(updatedParams);
  };

  const handleDisablePracticeDateFrom = (current: any) =>
    (current && current >= moment()) ||
    current.startOf("day") > moment(searchParams.practiceDateTo, DateFormat);

  const handleDisablePracticeDateTo = (current: any) => {
    const currentDay = current.startOf("day");
    const from = moment(
      searchParams.practiceDateFrom || new Date(0),
      DateFormat
    );
    return currentDay < from || currentDay > today;
  };
  const handleDisableReturnDateFrom = (current: any) =>
    (current && current >= moment()) ||
    current.startOf("day") > moment(searchParams.returnDateTo, DateFormat);

  const handleDisableReturnDateTo = (current: any) => {
    const currentDay = current.startOf("day");
    const from = moment(searchParams.returnDateFrom || new Date(0), DateFormat);
    return currentDay < from || currentDay > today;
  };

  return (
    <SearchWrapper data-testid={testIds.tabAppraisals.container}>
      <FormRow>
        {/* TARGA */}
        <InputWithBadge
          label={t("search-appraisals-plate-label")}
          labelAlign="left"
          updateFilterTag={(val) => handleChangeSearchParams(val, "plates")}
          dataTestId={testIds.tabAppraisals.fields.plate}
        />
        <RowSpacer />
        {/* NUMERO SINISTRO */}
        <InputWithBadge
          label={t("search-appraisals-claim-number-label")}
          labelAlign="left"
          updateFilterTag={(val) =>
            handleChangeSearchParams(val, "claimsNumber")
          }
          dataTestId={testIds.tabAppraisals.fields.claimsNumber}
        />
      </FormRow>

      <FormRow>
        {/* ID INCARICO DEKRA */}
        <InputWithBadge
          label={t("search-appraisals-id-practice-dekra-label")}
          labelAlign="left"
          updateFilterTag={(val) =>
            handleChangeSearchParams(val, "practicesNumber")
          }
          dataTestId={testIds.tabAppraisals.fields.practicesNumber}
        />
        <RowSpacer />
        {/* ID INCARICO CLIENTE */}
        <InputWithBadge
          label={t("search-appraisals-id-practice-company-label")}
          labelAlign="left"
          updateFilterTag={(val) =>
            handleChangeSearchParams(val, "practiceNumberCompany")
          }
          dataTestId={testIds.tabAppraisals.fields.practiceNumberCompany}
        />
      </FormRow>

      {/* STUDIO */}
      <FormRow>
        {!isUserExternalAppraiser ? (
          <SelectAppraiser
            appraiser={undefined}
            onChange={() => {}}
            onSelect={(appraiser) =>
              handleChangeSearchParams(appraiser.id, "idAppraiser")
            }
          />
        ) : (
          <div style={{ flex: 1 }}></div>
        )}

        <RowSpacer />
        {/* STATO */}
        <AutocompletePracticeStatus
          onSelect={(practiceStatusCode) =>
            handleChangeSearchParams(practiceStatusCode, "status")
          }
        />
      </FormRow>

      {/* TELAIO */}
      <FormRow>
        <InputWithBadge
          label={t("search-appraisals-chassis-number")}
          labelAlign="left"
          updateFilterTag={(val) =>
            handleChangeSearchParams(val, "chassisNumber")
          }
          mimLength={7}
          maxLength={17}
          //   dataTestId={testIds.tabAppraisals.fields.chassisNumber}
        />

        <RowSpacer />
        {/* IS NEGATIVE? */}
        <SwitchStyled
          label={t("search-appraisals-isNegative-label")}
          onChange={(value) => handleChangeSearchParams(value, "isNegative")}
          unCheckedChildren={t("switch-no")}
          checkedChildren={t("switch-yes")}
          //dataTestId={testIds.tabAppraisals.fields.isNegative}
        />
      </FormRow>

      <FormRow>
        {/* DATA APPUNTAMENTO */}
        <DateWrapper>
          <DatePickerStyled
            label={t("search-appraisals-appointment-date-label-from")}
            labelAlign="left"
            value={searchParams.appointmentDateFrom}
            onChange={(value) =>
              handleChangeSearchParams(value, "appointmentDateFrom")
            }
            placeholder={"gg-mm-aaaa"}
            //dataTestId={testIds.tabAppraisals.fields.appointmentDateFrom}
          />
          <RowSpacer />
          <DatePickerStyled
            label={t("search-appraisals-appointment-date-label-to")}
            value={searchParams.appointmentDateTo}
            onChange={(value) =>
              handleChangeSearchParams(value, "appointmentDateTo")
            }
            placeholder={"gg-mm-aaaa"}
            //dataTestId={testIds.tabAppraisals.fields.appointmentDateTo}
          />
        </DateWrapper>
      </FormRow>

      <FormRow>
        {/* DATA INCARICO */}
        <DateWrapper>
          <DatePickerStyled
            label={t("search-appraisals-assignment-date-label-from")}
            labelAlign="left"
            value={searchParams.practiceDateFrom}
            disabledDate={handleDisablePracticeDateFrom}
            onChange={(value) =>
              handleChangeSearchParams(value, "practiceDateFrom")
            }
            placeholder={"gg-mm-aaaa"}
            dataTestId={testIds.tabAppraisals.fields.practiceDateFrom}
          />
          <RowSpacer />
          <DatePickerStyled
            label={t("search-appraisals-assignment-date-label-to")}
            value={searchParams.practiceDateTo}
            disabledDate={handleDisablePracticeDateTo}
            onChange={(value) =>
              handleChangeSearchParams(value, "practiceDateTo")
            }
            placeholder={"gg-mm-aaaa"}
            dataTestId={testIds.tabAppraisals.fields.practiceDateTo}
          />
        </DateWrapper>

        <RowSpacer />

        {/* DATA RESTITUZIONE */}
        <DateWrapper>
          <DatePickerStyled
            label={t("search-appraisals-return-date-label-from")}
            labelAlign="left"
            value={searchParams.returnDateFrom}
            disabledDate={handleDisableReturnDateFrom}
            onChange={(value) =>
              handleChangeSearchParams(value, "returnDateFrom")
            }
            placeholder={"gg-mm-aaaa"}
            dataTestId={testIds.tabAppraisals.fields.returnDateFrom}
          />
          <RowSpacer />
          <DatePickerStyled
            label={t("search-appraisals-return-date-label-to")}
            value={searchParams.returnDateTo}
            disabledDate={handleDisableReturnDateTo}
            onChange={(value) =>
              handleChangeSearchParams(value, "returnDateTo")
            }
            placeholder={"gg-mm-aaaa"}
            dataTestId={testIds.tabAppraisals.fields.returnDateTo}
          />
        </DateWrapper>
      </FormRow>

      {/* STUDIO */}
      <FormRow>
        {/* CONCORDATO (solo cliente Prima) */}
        {customerPrima || (
          <SelectAgreement
            onChange={(val: any) => handleChangeSearchParams(val, "agreed")}
            value={searchParams.agreed as AgreementType}
          />

          // <SwitchStyled
          //   label={t("search-appraisals-isAgreedAmount-label")}
          //   onChange={(value) => handleChangeSearchParams(value, "agreed")}
          //   unCheckedChildren={t("switch-no")}
          //   checkedChildren={t("switch-yes")}
          //   dataTestId={testIds.tabAppraisals.fields.agreed}
          // />
        )}

        <RowSpacer />

        <SelectPracticeType
          onChange={(val: any): void =>
            handleChangeSearchParams(val, "practiceTypeCode")
          }
          value={(searchParams.practiceTypeCode as AppraisalTypeTypes) || ""}
          allowBlankItem={true}
          dataTestId={testIds.tabAppraisals.fields.status}
        />
      </FormRow>

      <SearchButtonWrapper>
        {onCancel && (
          <ButtonCancel onClick={onCancel}>{t("button-cancel")!}</ButtonCancel>
        )}
        <ButtonConfirm
          onClick={handleOnSearch}
          dataTestId={testIds.tabAppraisals.buttonSearch}
        >
          {t("button-search")!}
        </ButtonConfirm>
      </SearchButtonWrapper>
    </SearchWrapper>
  );
};

export default SearchFormAppraisals;
