import { useTranslation } from "react-i18next";
import {
  AppraiserUser,
  useLazyGetAppraiserQuery,
} from "../../../../../redux/apiSpecifications/apiFesf";
import { ContentWrapper, GenericWrapper } from "../../Commons/Wrappers";
import { Address } from "../../../../../redux/apiSpecifications/apiCrud";
import styled from "styled-components";
import { ButtonConfirm } from "../../../../Layout/Buttons";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AppraiserDetailsAddress from "./AppraiserDetailsAddress";
import { SubjectType } from "../../../../../config/const";
import { checkAddress } from "../../../../AzureMapsAddress/AddressAutocomplete";
import {
  setAppraiserAddresses,
  setAppraiserError,
  setAppraiserMainData,
  setAppraiserStatus,
  setValidation,
} from "../../../../../redux/features/appraisersSlice";
import { RootState } from "../../../../../redux/store";
import { useAuthorization } from "../../../../../hooks/useAuthorization";

const AddressesContainer = styled(ContentWrapper)`
  overflow: auto;
  max-height: calc(100vh - 16em);
  padding-right: 2em;
`;

const AddressesWrapper = styled(GenericWrapper)`
  display: flex;
  flex-direction: column;
  gap: 5em;

  align-items: center;
  padding: 3em;

  .buttons-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 95%;
    max-width: 900px;

    .button-wrapper {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
`;

interface IAppraiserDetailsAddressesProps {
  idAppraiser?: number;
  // appraiser: AppraiserUser | undefined;
  // onChange: (appraiser: AppraiserUser) => void;
}

const AppraiserDetailsAddresses = ({
  idAppraiser,
}: IAppraiserDetailsAddressesProps) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { getAuthorization } = useAuthorization();

  const appraiserData = useSelector((state: RootState) =>
    state.appraisers.appraisers.find((a) => a.id === idAppraiser)
  );

  const [loadAppraiser] = useLazyGetAppraiserQuery();

  const appraiser = appraiserData?.mainData;
  const addresses = appraiser?.addresses || [];

  useEffect(() => {
    if (idAppraiser !== undefined) {
      dispatch(
        setAppraiserStatus({
          id: idAppraiser,
          status: "loading",
        })
      );

      (async () => {
        const response = await loadAppraiser({
          authorization: await getAuthorization(),
          id: idAppraiser,
        });

        if (response.isSuccess && response.data) {
          dispatch(
            setAppraiserMainData({
              appraiser: response.data as AppraiserUser,
            })
          );
        } else {
          dispatch(
            setAppraiserError({
              id: idAppraiser,
              message: "Errore caricamento dettagli perito",
            })
          );
        }
      })();
    }
  }, []);

  const handleOnAddAddress = () => {
    const id = 0;
    const preferred = addresses?.length === 0;

    const updatedAddresses = [...addresses, { id, preferred } as Address];

    dispatch(
      setAppraiserAddresses({
        id: idAppraiser!,
        data: updatedAddresses,
        isUpdate: true,
      })
    );

    validateAddresses(updatedAddresses);

    // onChange({ ...appraiser, addresses: updatedAddress });
  };

  const handleOnDeleteAddress = (index: number) => {
    const updatedAddresses = addresses?.map((a, i) =>
      i !== index ? a : { ...a, isDeleted: true }
    );

    dispatch(
      setAppraiserAddresses({
        id: idAppraiser!,
        data: updatedAddresses,
        isUpdate: true,
      })
    );

    validateAddresses(updatedAddresses);

    // onChange({ ...appraiser, addresses: updatedAddresses });
  };

  const handleOnChangeAddress = (address: Address, index: number) => {
    let updatedAddresses = address.preferred
      ? addresses?.map((a) => ({ ...a, preferred: false }))
      : addresses;

    updatedAddresses = updatedAddresses?.map((a, i) =>
      i === index ? address : a
    );

    dispatch(
      setAppraiserAddresses({
        id: idAppraiser!,
        data: updatedAddresses,
        isUpdate: true,
      })
    );

    validateAddresses(updatedAddresses);

    // onChange({ ...appraiser, addresses: updatedAddresses });
  };

  const validateAddresses = (_addresses: Address[]) => {
    let areAddressesValid = (_addresses?.length || 0) > 0;

    _addresses.forEach((a) => {
      if (!a.isDeleted) areAddressesValid &&= checkAddress(a);
    });

    dispatch(
      setValidation({
        id: appraiser?.id!,
        validationErrors: {
          addresses: areAddressesValid ? [] : ["invalid data"],
        },
      })
    );
  };

  return (
    <AddressesContainer>
      <AddressesWrapper>
        {addresses.map(
          (address, index) =>
            address.isDeleted !== true && (
              <AppraiserDetailsAddress
                key={index}
                index={index}
                address={address}
                subjectType={appraiser?.subjectType as SubjectType}
                onDelete={handleOnDeleteAddress}
                onChange={handleOnChangeAddress}
              />
            )
        )}

        {/* <pre>{JSON.stringify(appraiserData?.pendingChanges, null, 2)}</pre>
        <pre>{JSON.stringify(appraiserData?.hasPendingChanges, null, 2)}</pre>
        <pre>{JSON.stringify(appraiserData?.validationErrors, null, 2)}</pre> */}

        <div className="buttons-wrapper">
          <div className="button-wrapper">
            <ButtonConfirm onClick={handleOnAddAddress}>
              {t("subject-address-button-add")!}
            </ButtonConfirm>
          </div>
        </div>
      </AddressesWrapper>
    </AddressesContainer>
  );
};

export default AppraiserDetailsAddresses;
