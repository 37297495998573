import { useTranslation } from "react-i18next";
import {
  AppraiserUser,
  useLazyGetAppraiserQuery,
} from "../../../../../redux/apiSpecifications/apiFesf";
import { ContentWrapper, GenericWrapper } from "../../Commons/Wrappers";
import { Contact } from "../../../../../redux/apiSpecifications/apiCrud";
import AppraiserDetailsContact from "./AppraiserDetailsContact";
import styled from "styled-components";
import { ButtonConfirm } from "../../../../Layout/Buttons";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { checkEmailFormat, isNotEmpty } from "../../../../../validation/common";
import {
  setAppraiserContacts,
  setAppraiserError,
  setAppraiserMainData,
  setAppraiserStatus,
  setValidation,
} from "../../../../../redux/features/appraisersSlice";
import { useAuthorization } from "../../../../../hooks/useAuthorization";
import { RootState } from "../../../../../redux/store";

const ContactsContainer = styled(ContentWrapper)`
  overflow: auto;
  max-height: calc(100vh - 16em);
  padding-right: 2em;
`;

const ContactsWrapper = styled(GenericWrapper)`
  display: flex;
  flex-direction: column;
  gap: 3em;

  align-items: center;
  padding: 1em;

  .buttons-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    //max-width: 900px;

    .button-wrapper {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
`;

export const checkContact = (c: Contact): boolean => {
  const { type, useCase, value } = c;
  const basicControls =
    isNotEmpty(type) && isNotEmpty(useCase) && isNotEmpty(value);

  if (!["E", "P"].includes(type || "")) return basicControls;
  else {
    return basicControls && checkEmailFormat(value || "");
  }
};

interface IAppraiserDetailsContactsProps {
  idAppraiser?: number;
  // appraiser: AppraiserUser | undefined;
  // onChange: (appraiser: AppraiserUser) => void;
}

const AppraiserDetailsContacts = ({
  idAppraiser,
}: IAppraiserDetailsContactsProps) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { getAuthorization } = useAuthorization();

  const appraiserData = useSelector((state: RootState) =>
    state.appraisers.appraisers.find((a) => a.id === idAppraiser)
  );

  const [loadAppraiser] = useLazyGetAppraiserQuery();

  const appraiser = appraiserData?.mainData;
  const contacts = appraiser?.contacts || [];

  useEffect(() => {
    if (idAppraiser !== undefined) {
      dispatch(
        setAppraiserStatus({
          id: idAppraiser,
          status: "loading",
        })
      );

      (async () => {
        const response = await loadAppraiser({
          authorization: await getAuthorization(),
          id: idAppraiser,
        });

        if (response.isSuccess && response.data) {
          dispatch(
            setAppraiserMainData({
              appraiser: response.data as AppraiserUser,
            })
          );

          // clear eventual fiscal code auto set to changed
          // setTimeout(() => {
          //   dispatch(clearPendingChanges(idAppraiser));
          // }, 200);
        } else {
          dispatch(
            setAppraiserError({
              id: idAppraiser,
              message: "Errore caricamento dettagli perito",
            })
          );
        }
      })();
    }
  }, []);

  const handleOnAddContact = () => {
    const id = 0;
    const preferred = contacts?.length === 0;

    const updatedContacts = [...contacts, { id, preferred } as Contact];

    dispatch(
      setAppraiserContacts({
        id: idAppraiser!,
        data: updatedContacts,
        isUpdate: true,
      })
    );

    validateConctacts(updatedContacts);

    // props.onChange({ ...appraiser, contacts: updatedContacts });
  };

  const handleOnDeleteContact = (index: number) => {
    const updatedContacts = contacts?.map((c, i) =>
      i !== index ? c : { ...c, isDeleted: true }
    );

    let areContactsValid = (updatedContacts?.length || 0) > 0;

    appraiser?.contacts?.forEach((c) => {
      if (!c.isDeleted) areContactsValid &&= checkContact(c);
    });

    dispatch(
      setAppraiserContacts({
        id: idAppraiser!,
        data: updatedContacts,
        isUpdate: true,
      })
    );

    validateConctacts(updatedContacts);

    // onChange({ ...appraiser, contacts: updatedContacts });
  };

  const handleOnChangeContact = (contact: Contact, index: number) => {
    let updatedContacts = contact.preferred
      ? contacts?.map((c, i) => ({ ...c, preferred: false }))
      : contacts;

    updatedContacts = updatedContacts?.map((c, i) =>
      i === index ? contact : c
    );

    dispatch(
      setAppraiserContacts({
        id: idAppraiser!,
        data: updatedContacts,
        isUpdate: true,
      })
    );

    validateConctacts(updatedContacts);

    // props.onChange({ ...appraiser, contacts: updatedContacts });
  };

  const validateConctacts = (_contacts: Contact[]) => {
    let areContactsValid = (_contacts?.length || 0) > 0;

    _contacts.forEach((c) => {
      if (!c.isDeleted) areContactsValid &&= checkContact(c);
    });

    dispatch(
      setValidation({
        id: appraiser?.id!,
        validationErrors: {
          contacts: areContactsValid ? [] : ["invalid data"],
        },
      })
    );
  };

  //useState<boolean>(false);

  const getAvailableUseCases = (
    type: string | undefined,
    index: number
  ): string[] => {
    const alreadyUsedUseCases = contacts
      .filter((c, i) => c.type === type && c.isDeleted !== true && i !== index)
      .map((c) => c.useCase);
    return ["", "IPR", "PUB", "ICO", "FAT", "DDE", "RDE"].filter(
      (uc) => !alreadyUsedUseCases.includes(uc)
    );
  };

  return (
    <ContactsContainer>
      <ContactsWrapper>
        {contacts.map(
          (contact, index) =>
            contact.isDeleted !== true && (
              <AppraiserDetailsContact
                key={index}
                index={index}
                contact={contact}
                availableUseCases={getAvailableUseCases(contact.type, index)}
                onDelete={handleOnDeleteContact}
                onChange={handleOnChangeContact}
              />
            )
        )}

        {/* <pre>{JSON.stringify(appraiserData?.pendingChanges, null, 2)}</pre>
        <pre>{JSON.stringify(appraiserData?.hasPendingChanges, null, 2)}</pre>
        <pre>{JSON.stringify(appraiserData?.validationErrors, null, 2)}</pre> */}

        <div className="buttons-wrapper">
          <div className="button-wrapper">
            <ButtonConfirm onClick={handleOnAddContact}>
              {t("subject-contact-button-add")!}
            </ButtonConfirm>
          </div>
        </div>
      </ContactsWrapper>
    </ContactsContainer>
  );
};

export default AppraiserDetailsContacts;
