import { useEffect, useMemo, useRef, useState } from "react";
import { RootState } from "../../../../../redux/store";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useAuthorization } from "../../../../../hooks/useAuthorization";
import styled from "styled-components";
import { ContentWrapper } from "../../Commons/Wrappers";
import { Collapse } from "antd";
import PanelHeaderWithButtons, {
  PanelStyled,
} from "../../Commons/PanelHeaderWithButtons";
import { InputTextStyled, SelectStyled } from "../../../../../style/Input";
import { SelectPair } from "../../../../../types/common.types";
import { ButtonConfirm, PanelHeaderIconButton } from "../../../../Layout/Buttons";
import {
  AppraisalDocumentTypes,
  PracticeAttachment,
  PracticeDocument,
  UploadPracticeDocumentApiResponse,
  useDeleteDocumentAttachmentMutation,
  useLazyGetAllPracticeDocumentsQuery,
  useLazyGetDocumentTypesQuery,
  useUploadPracticeDocumentMutation,
} from "../../../../../redux/apiSpecifications/apiCrud";
import AppraisalDetailsDocument from "./AppraisalDetailsDocument";
import { downloadFile, fileToBase64 } from "../../../../../utils/fileUtils";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { SerializedError } from "@reduxjs/toolkit";
import { RefreshInTabWrapper } from "../../../../../style/DashbordWidgetWrappers";
import { IconDownloadAll, IconLoading } from "../../../../../config/icons";
import { useLazyDownloadPracticeAttachmentsQuery } from "../../../../../redux/apiSpecifications/apiDocs";
import AppraisalDetailsINIFile from "./AppraisalDetailsINIFile";
import AppraisalConservativeAgreement from "./AppraisalConservativeAgreement";

// Styled Components

const AppraisalDetailsDocumentationWrapper = styled(ContentWrapper)`
  overflow: auto;
  max-height: calc(100vh - 16em);
  padding-right: 2em;
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 1em;
  overflow: hidden;
  padding: 1em;
  margin-bottom: 1em;

  .first-line {
    display: flex;
    flex-direction: row;
    width: 100%;

    .ant-form-item-row {
      width: 28em;
    }

    .input-filetype {
      width: 18em;
    }

    label {
      width: 8em;
    }
  }

  .second-line {
    margin-top: 1em;

    label {
      width: 8em;
    }

    .input-filetype {
      width: 18em;
    }
  }
`;

const DocumentsButtonsWrapper = styled.div`
  display: flex;
  gap: 1em;
  align-items: center;
  justify-content: flex-end;
`;

const DocumentsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

/**
 * @description AppraisalDetailsDocumentation props
 */
interface IAppraisalDetailsDocumentationProps {
  idPractice: number | undefined;
  isPracticeInvoiced: boolean;
}

/**
 * @description Allow to upload documents and show the list of documents already available
 */
const AppraisalDetailsDocumentation = ({
  idPractice,
  isPracticeInvoiced,
}: IAppraisalDetailsDocumentationProps) => {
  // Common variables
  const { t } = useTranslation();

  const { getAuthorization } = useAuthorization();

  // Local state
  // const refAddDocumentPdf = useRef<HTMLInputElement | null>(null);
  // const refAddDocumentImg = useRef<HTMLInputElement | null>(null);
  // const refAddDocumentAll = useRef<HTMLInputElement | null>(null);
  const refAddDocument = useRef<HTMLInputElement | null>(null);

  const { activeRole } = useSelector((state: RootState) => state.user);
  const [loadingStatus, setloadingStatus] = useState<
    "idle" | "loading" | "saving"
  >("idle");
  const [error, setError] = useState<string | undefined>();
  const [documents, setDocuments] = useState<PracticeDocument[]>([]);
  const [newDocumentCode, setNewDocumentCode] = useState<string | undefined>();
  const [newDocumentCategory, setNewDocumentCategory] = useState<
    string | undefined
  >();
  const [modifiedDocumentsIds, setModifiedDocumentsIds] = useState<number[]>(
    []
  );

  const [filenameFilter, setFilenameFilter] = useState<string | undefined>();

  const [modified, setModified] = useState<boolean>(false);

  const [documentsCategory, setDocumentsCategory] =
    useState<AppraisalDocumentTypes>([]);

  // Fetch & Save
  const [getDocumentTypes] = useLazyGetDocumentTypesQuery();
  const [getDocuments] = useLazyGetAllPracticeDocumentsQuery();
  const [saveDocument] = useUploadPracticeDocumentMutation();
  const [deleteAttachment] = useDeleteDocumentAttachmentMutation();
  const [downloadPracticeAttachments] =
    useLazyDownloadPracticeAttachmentsQuery();

  const otherTypes = ["AAP", "AIN", "ACL"];

  const removeMimeType = (base64: string) => {
    const separatorPosition = base64.indexOf(",");
    return base64.substring(separatorPosition + 1);
  };

  const documentsTypes = useMemo(() => {
    const availableDocuments: SelectPair[] = [];
    ["RR", "AR", "RP", "ACC", "PAC", "ID"].map((code) => {
      if (!documents.find((d) => d.documentTypeCode === code))
        availableDocuments.push({
          value: code,
          label: t(`appraisal-details-tab-documents-filetype-${code}`),
        });
    });

    // sort and append "Altro"
    const sortedAvailableDocuments = availableDocuments.sort((a, b) =>
      a.label.localeCompare(b.label)
    );

    sortedAvailableDocuments.push({
      value: "other",
      label: t("appraisal-details-tab-documents-filetype-other"),
    });

    return sortedAvailableDocuments;
  }, [documents]);

  const categoryTypes = useMemo(
    () =>
      ["I", "A", "C"].map((code) => ({
        value: code,
        label: t(`appraisal-details-tab-documents-category-${code}`),
      })),
    []
  );

  //useEffects

  useEffect(() => {
    (async () => {
      setloadingStatus("loading");

      const authorization = await getAuthorization();

      const { data: documentsTypeData } = await getDocumentTypes({
        authorization,
        activeRole: activeRole!,
      });

      setDocumentsCategory(documentsTypeData as AppraisalDocumentTypes);

      const { data, error, isSuccess, isError } = await getDocuments({
        authorization,
        id: idPractice!,
      });

      if (isSuccess && data) {
        const documents: PracticeDocument[] = (
          data as PracticeDocument[]
        ).filter((d) => d.documentTypeCode !== "ACT"); // remove eventually Accordo Conservativo, it's generated on demand

        setDocuments(documents);
        setModifiedDocumentsIds([]);
      } else {
        setError(t("appraisal-details-tab-documents-load-documents-error")!);
      }

      setloadingStatus("idle");
    })();
  }, [idPractice]);

  // Handlers

  const handleChangeDocumentCode = (code: string) => {
    let newDocumentCategory = undefined;

    if (code === "other") {
      if (["E2", "E3"].includes(activeRole || "")) {
        newDocumentCategory = "A";
      } else if (["F1"].includes(activeRole || "")) {
        newDocumentCategory = "C";
      }
    } else {
      newDocumentCategory = documentsCategory.find(
        (dc) => dc.documentTypeCode === code
      )?.documentCategoryCode;
    }

    setNewDocumentCategory(newDocumentCategory);

    let _documentCode = code;
    if (code === "other") {
      if (activeRole == "E2") {
        _documentCode = "AIN";
      } else if (activeRole == "E3") {
        _documentCode = "AAP";
      } else if (activeRole == "F1") {
        _documentCode = "ACL";
      }
    }

    setNewDocumentCode(_documentCode);
  };

  const handleChangeNewDocumentCategory = (category: string) => {
    let _category = category;
    let _code = ["AIN", "AAP", "ACL"].includes(newDocumentCode || "")
      ? "other"
      : newDocumentCode;

    if (_code === "other") {
      if (activeRole == "E2") {
        _category = "I";
        _code = "AIN";
      } else if (activeRole == "E3") {
        _category = "A";
        _code = "AAP";
      } else if (activeRole == "F1") {
        _category = "C";
        _code = "ACL";
      } else {
        // BO
        if (category === "I") _code = "AIN";
        else if (category === "A") _code = "AAP";
        else if (category === "C") _code = "ACL";
      }
    }

    setNewDocumentCode(_code);
    setNewDocumentCategory(_category);
  };

  const handleOnClickInput = async (e: any) => {
    if (!e) return;
    // if (refAddDocumentPdf.current) {
    //   refAddDocumentPdf.current.value = ''; // Reset the input value
    // }
    // if (refAddDocumentImg.current) {
    //   refAddDocumentImg.current.value = ''; // Reset the input value
    // }
    // if (refAddDocumentAll.current) {
    //   refAddDocumentAll.current.value = ''; // Reset the input value
    // }
    if (refAddDocument.current) {
      refAddDocument.current.value = "";
    }

    return e.target;
  };

  const handleAddFirstAttachment = async (e: any) => {
    if (!e) return;
    const selectedFile = e.target.files[0];

    if (!selectedFile) return;

    const fileContent = await fileToBase64(selectedFile);

    const extension = selectedFile.name.split(".").pop();
    const documentsForCategory = documents.filter(
      (d) => d.categoryTypeCode === newDocumentCategory
    );
    const sameNameAttachments = documentsForCategory
      .flatMap((d) => d.attachments)
      ?.filter((a) =>
        a?.name?.includes(
          selectedFile.name.substring(0, selectedFile.name.indexOf("."))
        )
      );

    const fileName = sameNameAttachments.find(
      (a) => a?.name === selectedFile.name
    )
      ? selectedFile.name.substring(0, selectedFile.name.indexOf(".")) +
        `(${sameNameAttachments.length}).${extension}`
      : selectedFile.name;

    const firstAttachment = {
      id: 0,
      name: fileName,
      originalName: fileName,
      base64File: fileContent,
    } as PracticeAttachment;

    const name = otherTypes.includes(newDocumentCode || "")
      ? fileName
      : t(`appraisal-details-tab-documents-filetype-${newDocumentCode}`);

    const updatedDocuments = [
      ...documents,
      {
        id: 0,
        documentCode: "",
        documentTypeCode: newDocumentCode,
        categoryTypeCode: newDocumentCategory,
        name,
        attachments: [firstAttachment],
      } as PracticeDocument,
    ];

    setDocuments(updatedDocuments);
    setNewDocumentCode(undefined);
    setNewDocumentCategory(undefined);
    setModified(true);
  };

  const handleAddDocument = () => {
    // if (["RR", "AR", "RP", "ACC"].includes(newDocumentCode || ""))
    //   refAddDocumentPdf.current?.click();
    // else if (["PAC", "ID"].includes(newDocumentCode || ""))
    //   refAddDocumentImg.current?.click();
    // else if (otherTypes.includes(newDocumentCode || ""))
    //   refAddDocumentAll.current?.click();

    refAddDocument.current?.click();

    setModified(true);
  };

  const handleChangeDocument = (doc: PracticeDocument, index: number) => {
    let updatedDocumentsI = documentsI;
    let updatedDocumentsA = documentsA;
    let updatedDocumentsC = documentsC;
    let updatedDocumentsS = documentsS;

    if (doc.categoryTypeCode === "I")
      updatedDocumentsI = updatedDocumentsI.map((d, i) =>
        i === index ? doc : d
      );
    if (doc.categoryTypeCode === "A")
      updatedDocumentsA = updatedDocumentsA.map((d, i) =>
        i === index ? doc : d
      );
    if (doc.categoryTypeCode === "C")
      updatedDocumentsC = updatedDocumentsC.map((d, i) =>
        i === index ? doc : d
      );
    if (doc.categoryTypeCode === "S")
      updatedDocumentsS = updatedDocumentsS.map((d, i) =>
        i === index ? doc : d
      );

    const updatedDocuments = [
      ...updatedDocumentsI,
      ...updatedDocumentsA,
      ...updatedDocumentsC,
      ...updatedDocumentsS,
    ];

    if (doc.id) {
      const updatedModifiedDocumentsIds = Array.from(
        new Set([...modifiedDocumentsIds, doc.id])
      );
      setModifiedDocumentsIds(updatedModifiedDocumentsIds);
    }

    setDocuments(updatedDocuments);
    setModified(true);
  };

  const handleDeleteAttachment = async (
    category: string,
    docIndex: number,
    attIndex: number
  ) => {
    const document =
      category === "I"
        ? documentsI[docIndex]
        : category === "A"
        ? documentsA[docIndex]
        : category === "C"
        ? documentsC[docIndex]
        : category === "S"
        ? documentsS[docIndex]
        : undefined;

    const attachmentId = document!.attachments![attIndex].id!;

    if (attachmentId > 0) {
      setloadingStatus("saving");
      const authorization = await getAuthorization();

      const response = await deleteAttachment({
        authorization,
        id: idPractice!,
        idAttachment: attachmentId,
      });

      const correctResponse = response as {
        data: UploadPracticeDocumentApiResponse;
      };

      const errorResponse = response as {
        error: FetchBaseQueryError | SerializedError;
      };

      setloadingStatus("idle");
      if (correctResponse.data) {
        //
      } else if (errorResponse) {
        setError(t("appraisal-details-tab-documents-delete-documents-error")!);
        alert(error);
        return;
      }
    }
    let updatedDocuments: PracticeDocument[] = [];

    if (category === "I") {
      const updatedIDocuments = documentsI
        .map((doc, i) =>
          i !== docIndex
            ? doc
            : {
                ...doc,
                attachments: doc.attachments?.filter((_, i) => i !== attIndex),
              }
        )
        .filter((d) => d.attachments?.length);

      updatedDocuments = [
        ...updatedIDocuments,
        ...documentsA,
        ...documentsC,
        ...documentsS,
      ];
    }

    if (category === "A") {
      const updatedADocuments = documentsA
        .map((doc, i) =>
          i !== docIndex
            ? doc
            : {
                ...doc,
                attachments: doc.attachments?.filter((_, i) => i !== attIndex),
              }
        )
        .filter((d) => d.attachments?.length);
      updatedDocuments = [
        ...documentsI,
        ...updatedADocuments,
        ...documentsC,
        ...documentsS,
      ];
    }

    if (category === "C") {
      const updatedCDocuments = documentsC
        .map((doc, i) =>
          i !== docIndex
            ? doc
            : {
                ...doc,
                attachments: doc.attachments?.filter((_, i) => i !== attIndex),
              }
        )
        .filter((d) => d.attachments?.length);

      updatedDocuments = [
        ...documentsI,
        ...documentsA,
        ...updatedCDocuments,
        ...documentsS,
      ];
    }

    if (category === "S") {
      const updatedSDocuments = documentsS
        .map((doc, i) =>
          i !== docIndex
            ? doc
            : {
                ...doc,
                attachments: doc.attachments?.filter((_, i) => i !== attIndex),
              }
        )
        .filter((d) => d.attachments?.length);

      updatedDocuments = [
        ...documentsI,
        ...documentsA,
        ...documentsC,
        ...updatedSDocuments,
      ];
    }

    setDocuments(updatedDocuments);
  };

  const handleSaveDocuments = async () => {
    setloadingStatus("saving");

    const documentsToSave = documents.filter(
      (d) => d.id === 0 || modifiedDocumentsIds.includes(d.id!)
    );

    // save documents

    for (let i = 0; i < documentsToSave.length; i++) {
      const practiceDocument: PracticeDocument = {
        ...documentsToSave[i],
        attachments: documentsToSave[i].attachments?.map((a) => ({
          ...a,
          base64File: a.base64File
            ? removeMimeType(a.base64File!)
            : a.base64File,
        })),
      };

      const response = await saveDocument({
        authorization: await getAuthorization(),
        id: idPractice!,
        practiceDocument,
      });

      const correctResponse = response as {
        data: UploadPracticeDocumentApiResponse;
      };

      const errorResponse = response as {
        error: FetchBaseQueryError | SerializedError;
      };

      if (correctResponse.data) {
        const updatedDocumentsWithBase64 = (
          correctResponse.data as PracticeDocument[]
        ).map((doc) => ({
          ...doc,
          attachments: doc.attachments?.map((a, aIndex) => ({
            ...a,
            base64File:
              documents!.find((d) => d.id === doc.id)?.attachments?.[aIndex]
                ?.base64File ?? undefined,
          })),
        }));

        setDocuments(updatedDocumentsWithBase64);
        setModified(false);
      } else if (errorResponse) {
        setError(t("appraisal-details-tab-documents-save-documents-error")!);
      }
    }

    setModifiedDocumentsIds([]);
    setloadingStatus("idle");
  };

  const disableSaveButton = () => {
    const areThereNewDocuments = documents.find((d) => d.id === 0);
    const areThereNewAttachments = documents.find((d) =>
      d.attachments?.find((a) => a.id === 0)
    );

    return !modified && !areThereNewDocuments && !areThereNewAttachments;
  };

  const handleDownloadAllCategoryAttachment = async (
    documentCategory: string
  ) => {
    setloadingStatus("saving");

    const response = await downloadPracticeAttachments({
      // authorization: await getAuthorization(),
      activeRole: activeRole!,
      id: idPractice!,
      documentCategory,
    });

    if (response.isSuccess && response.data) {
      const attachment = response.data as PracticeAttachment;

      downloadFile(attachment.base64File!, attachment.originalName!);
    }

    setloadingStatus("idle");
  };

  const { documentsI, documentsA, documentsC, documentsS } = useMemo(() => {
    let documentsI = documents?.filter((d) => d.categoryTypeCode === "I");
    let documentsA = documents?.filter((d) => d.categoryTypeCode === "A");
    let documentsC = documents?.filter((d) => d.categoryTypeCode === "C");
    let documentsS = documents?.filter((d) => d.categoryTypeCode === "S");

    if (filenameFilter) {
      documentsI = documentsI?.filter(
        (d) =>
          !filenameFilter ||
          d.attachments?.find((a) =>
            a.name?.toLowerCase().includes(filenameFilter)
          )
      );

      documentsA = documentsA?.filter(
        (d) =>
          !filenameFilter ||
          d.attachments?.find((a) =>
            a.name?.toLowerCase().includes(filenameFilter)
          )
      );

      documentsC = documentsC?.filter(
        (d) =>
          !filenameFilter ||
          d.attachments?.find((a) =>
            a.name?.toLowerCase().includes(filenameFilter)
          )
      );

      documentsS = documentsS?.filter(
        (d) =>
          !filenameFilter ||
          d.attachments?.find((a) =>
            a.name?.toLowerCase().includes(filenameFilter)
          )
      );
    }

    const documentsISingle = documentsI?.filter(
      (d) => !["PAC", "ID"].includes(d.documentTypeCode || "")
    );

    const documentsIMultiple = documentsI?.filter((d) =>
      ["PAC", "ID"].includes(d.categoryTypeCode || "")
    );

    const documentsASingle = documentsA?.filter(
      (d) => !["PAC", "ID"].includes(d.documentTypeCode || "")
    );

    const documentsAMultiple = documentsA?.filter((d) =>
      ["PAC", "ID"].includes(d.documentTypeCode || "")
    );

    const documentsCSingle = documentsC?.filter(
      (d) => !["PAC", "ID"].includes(d.documentTypeCode || "")
    );

    const documentsCMultiple = documentsC?.filter((d) =>
      ["PAC", "ID"].includes(d.documentTypeCode || "")
    );

    const documentsSSingle = documentsS?.filter(
      (d) => !["PAC", "ID"].includes(d.documentTypeCode || "")
    );

    const documentsSMultiple = documentsS?.filter((d) =>
      ["PAC", "ID"].includes(d.documentTypeCode || "")
    );

    documentsI = [...documentsISingle, ...documentsIMultiple];
    documentsA = [...documentsASingle, ...documentsAMultiple];
    documentsC = [...documentsCSingle, ...documentsCMultiple];
    documentsS = [...documentsSSingle, ...documentsSMultiple];

    return { documentsI, documentsA, documentsC, documentsS };
  }, [documents, filenameFilter]);

  const mapSelectDocumentCode = (code: string | undefined) => {
    if (["other", "AAP", "AIN", "ACL"].includes(code || "")) return "other";

    return code;
  };

  return (
    <>
      {["loading", "saving"].includes(loadingStatus) && (
        <RefreshInTabWrapper>
          <div className="box">{IconLoading}</div>
        </RefreshInTabWrapper>
      )}

      <AppraisalDetailsDocumentationWrapper>
        <HeaderWrapper>
          <div className="first-line">
            <SelectStyled
              classNameInput="input-filetype"
              classNameItem="item-filetype"
              label={t("appraisal-details-tab-documents-filename-label")}
              options={documentsTypes}
              onChange={handleChangeDocumentCode}
              value={mapSelectDocumentCode(newDocumentCode)}
              disabled={isPracticeInvoiced}
            />
            <ButtonConfirm
              onClick={() => handleAddDocument()}
              disabled={!newDocumentCode || !newDocumentCategory}
            >
              {t("appraisal-details-tab-documents-add-button") || ""}
            </ButtonConfirm>
            {/* <input
              ref={refAddDocumentPdf}
              type="file"
              accept="application/pdf"
              style={{ display: "none" }}
              onClick={(e) => handleOnClickInput(e)}
              onChange={(e) => handleAddFirstAttachment(e)}
            />
            <input
              ref={refAddDocumentImg}
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              onClick={(e) => handleOnClickInput(e)}
              onChange={(e) => handleAddFirstAttachment(e)}
            /> */}
            <input
              ref={refAddDocument}
              type="file"
              accept="image/*, application/pdf"
              style={{ display: "none" }}
              onClick={(e) => handleOnClickInput(e)}
              onChange={(e) => handleAddFirstAttachment(e)}
            />
            {/* <input
              ref={refAddDocumentAll}
              type="file"
              style={{ display: "none" }}
              onClick={(e) => handleOnClickInput(e)}
              onChange={(e) => handleAddFirstAttachment(e)}
            /> */}
            <div style={{ flex: 1 }}></div>
            <ButtonConfirm
              disabled={disableSaveButton()}
              onClick={handleSaveDocuments}
            >
              {t("appraisal-details-tab-documents-save-documents")!}
            </ButtonConfirm>

            <div style={{ flex: 1 }}></div>
            <InputTextStyled
              placeholder={t(
                "appraisal-details-tab-documents-search-placeholder"
              )}
              onChange={setFilenameFilter}
              value={filenameFilter}
              disabled={isPracticeInvoiced}
            />
          </div>
          {["other", ...otherTypes].includes(newDocumentCode || "") &&
            ["D1", "D2", "E4"].includes(activeRole || "") && (
              <div className="second-line">
                <SelectStyled
                  classNameInput="input-filetype"
                  classNameItem="item-filetype"
                  label={t("appraisal-details-tab-documents-category-label")}
                  options={categoryTypes}
                  onChange={handleChangeNewDocumentCategory}
                  value={newDocumentCategory}
                />
              </div>
            )}
        </HeaderWrapper>

        <Collapse
          defaultActiveKey={["internal", "appraiser", "client", "system"]}
        >
          <PanelStyled
            key="internal"
            header={
              <PanelHeaderWithButtons
                text={t("appraisal-details-tab-documents-category-I")}
              />
            }
          >
            <DocumentsWrapper>
              {documentsI.map((d, i) => (
                <AppraisalDetailsDocument
                  key={i}
                  document={d}
                  onChange={(doc) => handleChangeDocument(doc, i)}
                  onDeleteAttachment={(index) =>
                    handleDeleteAttachment("I", i, index)
                  }
                />
              ))}
            </DocumentsWrapper>
          </PanelStyled>

          <PanelStyled
            key="appraiser"
            header={
              <PanelHeaderWithButtons
                text={t("appraisal-details-tab-documents-category-A")}
              />
            }
          >
            <DocumentsWrapper>
              {documentsA.map((d, i) => (
                <AppraisalDetailsDocument
                  key={i}
                  document={d}
                  onChange={(doc) => handleChangeDocument(doc, i)}
                  onDeleteAttachment={(index) =>
                    handleDeleteAttachment("A", i, index)
                  }
                />
              ))}
            </DocumentsWrapper>
          </PanelStyled>

          <PanelStyled
            key="client"
            extra={
              documentsC.length > 0 && (
                <PanelHeaderIconButton
                  icon={<IconDownloadAll />}
                  tooltip={t("TOOLTIPS.download-all-category-attachments")}
                  onClick={(event) => {
                    handleDownloadAllCategoryAttachment("C");

                    event.stopPropagation();
                  }}
                />
              )
            }
            header={t("appraisal-details-tab-documents-category-C")}
          >
            {/* {documentsC.length > 0 && (
              <DocumentsButtonsWrapper>
                <ButtonConfirm
                  onClick={() => handleDownloadAllCategoryAttachment("C")}
                >
                  {t("BUTTONS.download-all-category-attachments")!}
                </ButtonConfirm>
              </DocumentsButtonsWrapper>
            )} */}

            <DocumentsWrapper>
              {documentsC.map((d, i) => (
                <AppraisalDetailsDocument
                  key={i}
                  document={d}
                  readonly={true}
                  onChange={(doc) => handleChangeDocument(doc, i)}
                  onDeleteAttachment={(index) =>
                    handleDeleteAttachment("C", i, index)
                  }
                />
              ))}
            </DocumentsWrapper>
          </PanelStyled>

          <PanelStyled
            key="system"
            header={
              <PanelHeaderWithButtons
                text={t("appraisal-details-tab-documents-category-S")}
              />
            }
          >
            <DocumentsWrapper>
              <AppraisalDetailsINIFile idPractice={idPractice!} />
              <AppraisalConservativeAgreement idPractice={idPractice!} />
              {documentsS.map((d, i) => (
                <AppraisalDetailsDocument
                  key={i}
                  document={d}
                  readonly={true}
                  onChange={(doc) => handleChangeDocument(doc, i)}
                  onDeleteAttachment={(index) =>
                    handleDeleteAttachment("S", i, index)
                  }
                />
              ))}
            </DocumentsWrapper>
          </PanelStyled>
        </Collapse>
      </AppraisalDetailsDocumentationWrapper>
    </>
  );
};

export default AppraisalDetailsDocumentation;
