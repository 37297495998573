import { baseSplitApiCrud as api } from "./config/baseApiCrud";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    advancedSearch: build.query<
      AdvancedSearchApiResponse,
      AdvancedSearchApiArg
    >({
      query: (queryArg) => ({
        url: `/search`,
        headers: { Authorization: queryArg.authorization },
        params: {
          term: queryArg.term,
          subjectType: queryArg.subjectType,
          name: queryArg.name,
          lastname: queryArg.lastname,
          fiscalCode: queryArg.fiscalCode,
          phone: queryArg.phone,
          email: queryArg.email,
          businessName: queryArg.businessName,
          pIva: queryArg.pIva,
          plate: queryArg.plate,
          policyNumber: queryArg.policyNumber,
          Insurance: queryArg.insurance,
          claimNumber: queryArg.claimNumber,
        },
      }),
    }),
    createPolicy: build.mutation<CreatePolicyApiResponse, CreatePolicyApiArg>({
      query: (queryArg) => ({
        url: `/policies`,
        method: "POST",
        body: queryArg.policy,
        headers: { Authorization: queryArg.authorization },
      }),
    }),
    createClaim: build.mutation<CreateClaimApiResponse, CreateClaimApiArg>({
      query: (queryArg) => ({
        url: `/claims`,
        method: "POST",
        body: queryArg.claim,
        headers: { Authorization: queryArg.authorization },
      }),
    }),
    readClaim: build.query<ReadClaimApiResponse, ReadClaimApiArg>({
      query: (queryArg) => ({
        url: `/claims/${queryArg.id}`,
        headers: { Authorization: queryArg.authorization },
      }),
    }),
    updateClaim: build.mutation<UpdateClaimApiResponse, UpdateClaimApiArg>({
      query: (queryArg) => ({
        url: `/claims/${queryArg.id}`,
        method: "PUT",
        body: queryArg.claim,
        headers: { Authorization: queryArg.authorization },
      }),
    }),
    deleteClaim: build.mutation<DeleteClaimApiResponse, DeleteClaimApiArg>({
      query: (queryArg) => ({
        url: `/claims/${queryArg.id}`,
        method: "DELETE",
        headers: { Authorization: queryArg.authorization },
      }),
    }),
    getPracticesSummary: build.query<
      GetPracticesSummaryApiResponse,
      GetPracticesSummaryApiArg
    >({
      query: (queryArg) => ({
        url: `/practicesSummary/${queryArg.idTenant}`,
        headers: { Authorization: queryArg.authorization },
      }),
    }),
    updatePracticesSummary: build.mutation<
      UpdatePracticesSummaryApiResponse,
      UpdatePracticesSummaryApiArg
    >({
      query: (queryArg) => ({
        url: `/practicesSummary/${queryArg.idTenant}`,
        method: "PUT",
        body: queryArg.dashboardSummary,
        headers: { Authorization: queryArg.authorization },
      }),
    }),
    getPracticesStatus: build.query<
      GetPracticesStatusApiResponse,
      GetPracticesStatusApiArg
    >({
      query: (queryArg) => ({
        url: `/practices/processingstatus`,
        headers: { Authorization: queryArg.authorization },
        params: {
          practices: queryArg.practices,
          activeRole: queryArg.activeRole,
        },
      }),
    }),
    updatePracticesStatus: build.mutation<
      UpdatePracticesStatusApiResponse,
      UpdatePracticesStatusApiArg
    >({
      query: (queryArg) => ({
        url: `/practices/processingstatus`,
        method: "PUT",
        body: queryArg.practicesProcessingStatus,
        headers: { Authorization: queryArg.authorization },
      }),
    }),
    getPracticesD2SummaryByTenant: build.query<
      GetPracticesD2SummaryByTenantApiResponse,
      GetPracticesD2SummaryByTenantApiArg
    >({
      query: (queryArg) => ({
        url: `/practices/summary/d2/${queryArg.idTenant}`,
        headers: { Authorization: queryArg.authorization },
        params: {
          isDocumental: queryArg.isDocumental,
          appraiserType: queryArg.appraiserType,
          hasAppointment: queryArg.hasAppointment,
          practiceStatus: queryArg.practiceStatus,
          practiceStatusAggr: queryArg.practiceStatusAggr,
        },
      }),
    }),
    updateD2PracticesSummary: build.mutation<
      UpdateD2PracticesSummaryApiResponse,
      UpdateD2PracticesSummaryApiArg
    >({
      query: (queryArg) => ({
        url: `/practices/summary/d2/${queryArg.idTenant}`,
        method: "PUT",
        body: queryArg.dashboardD2Summary,
        headers: { Authorization: queryArg.authorization },
      }),
    }),
    getPracticesE2Summary: build.query<
      GetPracticesE2SummaryApiResponse,
      GetPracticesE2SummaryApiArg
    >({
      query: (queryArg) => ({
        url: `/practicesE2Summary/${queryArg.idTenant}`,
        headers: { Authorization: queryArg.authorization },
      }),
    }),
    updatePracticesE2Summary: build.mutation<
      UpdatePracticesE2SummaryApiResponse,
      UpdatePracticesE2SummaryApiArg
    >({
      query: (queryArg) => ({
        url: `/practicesE2Summary/${queryArg.idTenant}`,
        method: "PUT",
        body: queryArg.dashboardE2Summary,
        headers: { Authorization: queryArg.authorization },
      }),
    }),
    getF1PracticesSummaryByTenant: build.query<
      GetF1PracticesSummaryByTenantApiResponse,
      GetF1PracticesSummaryByTenantApiArg
    >({
      query: (queryArg) => ({
        url: `/practices/summary/f1/${queryArg.idTenant}`,
        headers: { Authorization: queryArg.authorization },
      }),
    }),
    fakeUpdateF1PracticesSummaryByTenant: build.mutation<
      FakeUpdateF1PracticesSummaryByTenantApiResponse,
      FakeUpdateF1PracticesSummaryByTenantApiArg
    >({
      query: (queryArg) => ({
        url: `/practices/summary/f1/${queryArg.idTenant}`,
        method: "PUT",
        body: queryArg.dashboardF1Summary,
        headers: { Authorization: queryArg.authorization },
      }),
    }),
    getE4PracticesSummaryByTenant: build.query<
      GetE4PracticesSummaryByTenantApiResponse,
      GetE4PracticesSummaryByTenantApiArg
    >({
      query: (queryArg) => ({
        url: `/practices/summary/E4/${queryArg.idTenant}`,
        headers: { Authorization: queryArg.authorization },
      }),
    }),
    fakeUpdateE4PracticesSummaryByTenant: build.mutation<
      FakeUpdateE4PracticesSummaryByTenantApiResponse,
      FakeUpdateE4PracticesSummaryByTenantApiArg
    >({
      query: (queryArg) => ({
        url: `/practices/summary/E4/${queryArg.idTenant}`,
        method: "PUT",
        body: queryArg.dashboardE4Summary,
        headers: { Authorization: queryArg.authorization },
      }),
    }),
    getE1PracticesSummaryByTenant: build.query<
      GetE1PracticesSummaryByTenantApiResponse,
      GetE1PracticesSummaryByTenantApiArg
    >({
      query: (queryArg) => ({
        url: `/practices/summary/E1/${queryArg.idTenant}`,
        headers: { Authorization: queryArg.authorization },
      }),
    }),
    fakeUpdateE1PracticesSummaryByTenant: build.mutation<
      FakeUpdateE1PracticesSummaryByTenantApiResponse,
      FakeUpdateE1PracticesSummaryByTenantApiArg
    >({
      query: (queryArg) => ({
        url: `/practices/summary/E1/${queryArg.idTenant}`,
        method: "PUT",
        body: queryArg.dashboardE1Summary,
        headers: { Authorization: queryArg.authorization },
      }),
    }),
    getPracticeDetail: build.query<
      GetPracticeDetailApiResponse,
      GetPracticeDetailApiArg
    >({
      query: (queryArg) => ({
        url: `/practiceDetail/${queryArg.id}`,
        headers: { Authorization: queryArg.authorization },
        params: { activeRole: queryArg.activeRole },
      }),
    }),
    updatePracticeDetail: build.mutation<
      UpdatePracticeDetailApiResponse,
      UpdatePracticeDetailApiArg
    >({
      query: (queryArg) => ({
        url: `/practiceDetail/${queryArg.id}`,
        method: "PUT",
        body: queryArg.writeOnlyPractice,
        headers: { Authorization: queryArg.authorization },
        params: { activeRole: queryArg.activeRole },
      }),
    }),
    practiceBlock: build.mutation<
      PracticeBlockApiResponse,
      PracticeBlockApiArg
    >({
      query: (queryArg) => ({
        url: `/practiceBlock/${queryArg.id}`,
        method: "PUT",
        headers: { Authorization: queryArg.authorization },
      }),
    }),
    practiceUnblock: build.mutation<
      PracticeUnblockApiResponse,
      PracticeUnblockApiArg
    >({
      query: (queryArg) => ({
        url: `/practiceUnblock/${queryArg.id}`,
        method: "PUT",
        headers: { Authorization: queryArg.authorization },
      }),
    }),
    getUserCommunicationsList: build.query<
      GetUserCommunicationsListApiResponse,
      GetUserCommunicationsListApiArg
    >({
      query: (queryArg) => ({
        url: `/communications/`,
        headers: { Authorization: queryArg.authorization },
        params: {
          senderType: queryArg.senderType,
          tenants: queryArg.tenants,
          categoryCode: queryArg.categoryCode,
          statusCode: queryArg.statusCode,
        },
      }),
    }),
    createCommunication: build.mutation<
      CreateCommunicationApiResponse,
      CreateCommunicationApiArg
    >({
      query: (queryArg) => ({
        url: `/communications/`,
        method: "POST",
        body: queryArg.communication,
        headers: { Authorization: queryArg.authorization },
      }),
    }),
    getAppraisersList: build.query<
      GetAppraisersListApiResponse,
      GetAppraisersListApiArg
    >({
      query: (queryArg) => ({
        url: `/practice-activities/appraisers-list`,
        headers: { Authorization: queryArg.authorization },
        params: {
          practiceId: queryArg.practiceId,
          tenantsId: queryArg.tenantsId,
          activeRole: queryArg.activeRole,
        },
      }),
    }),
    updateAppraisersList: build.mutation<
      UpdateAppraisersListApiResponse,
      UpdateAppraisersListApiArg
    >({
      query: (queryArg) => ({
        url: `/practice-activities/appraisers-list`,
        method: "PUT",
        body: queryArg.practiceActivityAppraiser,
        headers: { Authorization: queryArg.authorization },
      }),
    }),
    assignAppraiser: build.mutation<
      AssignAppraiserApiResponse,
      AssignAppraiserApiArg
    >({
      query: (queryArg) => ({
        url: `/practice-activities/assign-appraiser`,
        method: "POST",
        body: queryArg.assignmentData,
        headers: { Authorization: queryArg.authorization },
        params: { activeRole: queryArg.activeRole },
      }),
    }),
    suspendOrCancelPractice: build.mutation<
      SuspendOrCancelPracticeApiResponse,
      SuspendOrCancelPracticeApiArg
    >({
      query: (queryArg) => ({
        url: `/practice-activities/suspend-cancel-practice`,
        method: "POST",
        body: queryArg.suspendOrCancelData,
        headers: { Authorization: queryArg.authorization },
        params: { activeRole: queryArg.activeRole },
      }),
    }),
    restorePractice: build.mutation<
      RestorePracticeApiResponse,
      RestorePracticeApiArg
    >({
      query: (queryArg) => ({
        url: `/practice-activities/restore-practice`,
        method: "POST",
        body: queryArg.restorePracticeData,
        headers: { Authorization: queryArg.authorization },
        params: { activeRole: queryArg.activeRole },
      }),
    }),
    createPracticeNote: build.mutation<
      CreatePracticeNoteApiResponse,
      CreatePracticeNoteApiArg
    >({
      query: (queryArg) => ({
        url: `/practice-activities/notes`,
        method: "POST",
        body: queryArg.practiceNote,
        headers: { Authorization: queryArg.authorization },
      }),
    }),
    updatePracticeNote: build.mutation<
      UpdatePracticeNoteApiResponse,
      UpdatePracticeNoteApiArg
    >({
      query: (queryArg) => ({
        url: `/practice-activities/notes`,
        method: "PUT",
        body: queryArg.practiceNote,
        headers: { Authorization: queryArg.authorization },
      }),
    }),
    deletePracticeNote: build.mutation<
      DeletePracticeNoteApiResponse,
      DeletePracticeNoteApiArg
    >({
      query: (queryArg) => ({
        url: `/practice-activities/notes/${queryArg.id}`,
        method: "DELETE",
        headers: { Authorization: queryArg.authorization },
      }),
    }),
    getPracticeNotes: build.query<
      GetPracticeNotesApiResponse,
      GetPracticeNotesApiArg
    >({
      query: (queryArg) => ({
        url: `/practice-activities/notes-list/${queryArg.idPractice}`,
        headers: { Authorization: queryArg.authorization },
      }),
    }),
    createPracticeAppointment: build.mutation<
      CreatePracticeAppointmentApiResponse,
      CreatePracticeAppointmentApiArg
    >({
      query: (queryArg) => ({
        url: `/practice-activities/appointment`,
        method: "POST",
        body: queryArg.practiceAppointment,
        headers: { Authorization: queryArg.authorization },
        params: { activeRole: queryArg.activeRole },
      }),
    }),
    acceptPracticeAssignment: build.mutation<
      AcceptPracticeAssignmentApiResponse,
      AcceptPracticeAssignmentApiArg
    >({
      query: (queryArg) => ({
        url: `/practice-activities/accept-assignment/${queryArg.idPractice}`,
        method: "POST",
        headers: { Authorization: queryArg.authorization },
      }),
    }),
    setOutOfCoverageArea: build.mutation<
      SetOutOfCoverageAreaApiResponse,
      SetOutOfCoverageAreaApiArg
    >({
      query: (queryArg) => ({
        url: `/practice-activities/set-out-of-coverage-area/${queryArg.idPractice}`,
        method: "POST",
        body: queryArg.outOfCoverageAppraisalSubject,
        headers: { Authorization: queryArg.authorization },
        params: { activeRole: queryArg.activeRole },
      }),
    }),
    updatePracticeRates: build.mutation<
      UpdatePracticeRatesApiResponse,
      UpdatePracticeRatesApiArg
    >({
      query: (queryArg) => ({
        url: `/practice-activities/rate/${queryArg.idPractice}`,
        method: "PUT",
        body: queryArg.practiceRate,
        headers: { Authorization: queryArg.authorization },
        params: { activeRole: queryArg.activeRole },
      }),
    }),
    updatePracticeFees: build.mutation<
      UpdatePracticeFeesApiResponse,
      UpdatePracticeFeesApiArg
    >({
      query: (queryArg) => ({
        url: `/practice-activities/fee/${queryArg.idPractice}`,
        method: "PUT",
        body: queryArg.practiceFee,
        headers: { Authorization: queryArg.authorization },
        params: { activeRole: queryArg.activeRole },
      }),
    }),
    getDocumentTypes: build.query<
      GetDocumentTypesApiResponse,
      GetDocumentTypesApiArg
    >({
      query: (queryArg) => ({
        url: `/practice-documents/document-types/`,
        headers: { Authorization: queryArg.authorization },
        params: { activeRole: queryArg.activeRole },
      }),
    }),
    uploadPracticeDocument: build.mutation<
      UploadPracticeDocumentApiResponse,
      UploadPracticeDocumentApiArg
    >({
      query: (queryArg) => ({
        url: `/practice-documents/${queryArg.id}`,
        method: "POST",
        body: queryArg.practiceDocument,
        headers: { Authorization: queryArg.authorization },
      }),
    }),
    getIniFile: build.query<GetIniFileApiResponse, GetIniFileApiArg>({
      query: (queryArg) => ({
        url: `/practice-documents/get-ini/${queryArg.id}`,
        headers: { Authorization: queryArg.authorization },
      }),
    }),
    getConservativeAgreementFile: build.query<
      GetConservativeAgreementFileApiResponse,
      GetConservativeAgreementFileApiArg
    >({
      query: (queryArg) => ({
        url: `/practice-documents/get-conservative-agreement/${queryArg.id}`,
        headers: { Authorization: queryArg.authorization },
      }),
    }),
    deleteDocumentAttachment: build.mutation<
      DeleteDocumentAttachmentApiResponse,
      DeleteDocumentAttachmentApiArg
    >({
      query: (queryArg) => ({
        url: `/practice-documents/attachments/${queryArg.id}`,
        method: "DELETE",
        headers: { Authorization: queryArg.authorization },
        params: { idAttachment: queryArg.idAttachment },
      }),
    }),
    getAllPracticeDocuments: build.query<
      GetAllPracticeDocumentsApiResponse,
      GetAllPracticeDocumentsApiArg
    >({
      query: (queryArg) => ({
        url: `/practice-documents/all-documents/${queryArg.id}`,
        headers: { Authorization: queryArg.authorization },
      }),
    }),
    getPracticeHistory: build.query<
      GetPracticeHistoryApiResponse,
      GetPracticeHistoryApiArg
    >({
      query: (queryArg) => ({
        url: `/practice-history/${queryArg.idPractice}`,
        headers: { Authorization: queryArg.authorization },
      }),
    }),
    fakeUpdatePracticeHistory: build.mutation<
      FakeUpdatePracticeHistoryApiResponse,
      FakeUpdatePracticeHistoryApiArg
    >({
      query: (queryArg) => ({
        url: `/practice-history/${queryArg.idPractice}`,
        method: "PUT",
        body: queryArg.practiceHistory,
        headers: { Authorization: queryArg.authorization },
      }),
    }),
    getAllPracticeRequests: build.query<
      GetAllPracticeRequestsApiResponse,
      GetAllPracticeRequestsApiArg
    >({
      query: (queryArg) => ({
        url: `/practice-requests/${queryArg.id}`,
        headers: { Authorization: queryArg.authorization },
        params: { activeRole: queryArg.activeRole },
      }),
    }),
    createPracticeRequest: build.mutation<
      CreatePracticeRequestApiResponse,
      CreatePracticeRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/practice-requests/${queryArg.id}`,
        method: "POST",
        body: queryArg.secondaryPracticeRequest,
        headers: { Authorization: queryArg.authorization },
        params: { activeRole: queryArg.activeRole },
      }),
    }),
    getRequestList: build.query<
      GetRequestListApiResponse,
      GetRequestListApiArg
    >({
      query: (queryArg) => ({
        url: `/practice-requests-list/`,
        headers: { Authorization: queryArg.authorization },
        params: {
          lastMessageSenderType: queryArg.lastMessageSenderType,
          lastMessageReceiverType: queryArg.lastMessageReceiverType,
          tenants: queryArg.tenants,
          categoryCode: queryArg.categoryCode,
          statusCode: queryArg.statusCode,
          activeRole: queryArg.activeRole,
        },
      }),
    }),
    getPracticeRequest: build.query<
      GetPracticeRequestApiResponse,
      GetPracticeRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/practice-requests/requests/${queryArg.id}`,
        headers: { Authorization: queryArg.authorization },
        params: { activeRole: queryArg.activeRole },
      }),
    }),
    closeRequest: build.mutation<CloseRequestApiResponse, CloseRequestApiArg>({
      query: (queryArg) => ({
        url: `/practice-requests/requests/${queryArg.id}/close/`,
        method: "PUT",
        body: queryArg.requestMessage,
        headers: { Authorization: queryArg.authorization },
        params: { activeRole: queryArg.activeRole },
      }),
    }),
    forwardRequest: build.mutation<
      ForwardRequestApiResponse,
      ForwardRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/practice-requests/requests/${queryArg.id}/forward`,
        method: "PUT",
        body: queryArg.requestMessage,
        headers: { Authorization: queryArg.authorization },
        params: { activeRole: queryArg.activeRole },
      }),
    }),
    replyToRequestMessage: build.mutation<
      ReplyToRequestMessageApiResponse,
      ReplyToRequestMessageApiArg
    >({
      query: (queryArg) => ({
        url: `/practice-requests/requests/${queryArg.id}/reply`,
        method: "POST",
        body: queryArg.requestMessage,
        headers: { Authorization: queryArg.authorization },
        params: { activeRole: queryArg.activeRole },
      }),
    }),
    confirmRequestClosureCheck: build.mutation<
      ConfirmRequestClosureCheckApiResponse,
      ConfirmRequestClosureCheckApiArg
    >({
      query: (queryArg) => ({
        url: `/practice-requests/requests/${queryArg.id}/confirm-check`,
        method: "POST",
        headers: { Authorization: queryArg.authorization },
        params: { activeRole: queryArg.activeRole },
      }),
    }),
    getAllRequestCategoryByUserRole: build.query<
      GetAllRequestCategoryByUserRoleApiResponse,
      GetAllRequestCategoryByUserRoleApiArg
    >({
      query: (queryArg) => ({
        url: `/practices-requests/categories`,
        headers: { Authorization: queryArg.authorization },
        params: { activeRole: queryArg.activeRole },
      }),
    }),
    searchPracticesBo: build.mutation<
      SearchPracticesBoApiResponse,
      SearchPracticesBoApiArg
    >({
      query: (queryArg) => ({
        url: `/search-practices/bo`,
        method: "POST",
        body: queryArg.searchPracticesParams,
        headers: { Authorization: queryArg.authorization },
        params: { activeRole: queryArg.activeRole },
      }),
    }),
    searchPracticesAppraiser: build.mutation<
      SearchPracticesAppraiserApiResponse,
      SearchPracticesAppraiserApiArg
    >({
      query: (queryArg) => ({
        url: `/search-practices/appraiser`,
        method: "POST",
        body: queryArg.searchPracticesParams,
        headers: { Authorization: queryArg.authorization },
        params: { activeRole: queryArg.activeRole },
      }),
    }),
    updateAppraisalLocation: build.mutation<
      UpdateAppraisalLocationApiResponse,
      UpdateAppraisalLocationApiArg
    >({
      query: (queryArg) => ({
        url: `/practice-detail/appraisal-location/${queryArg.idPractice}`,
        method: "POST",
        body: queryArg.appraisalSubject,
        headers: { Authorization: queryArg.authorization },
        params: { activeRole: queryArg.activeRole },
      }),
    }),
    generateCongruityReport: build.mutation<
      GenerateCongruityReportApiResponse,
      GenerateCongruityReportApiArg
    >({
      query: (queryArg) => ({
        url: `/practice-activities/generate-congruity-report/${queryArg.id}`,
        method: "POST",
        body: queryArg.congruityReport,
        headers: { Authorization: queryArg.authorization },
        params: { version: queryArg.version },
      }),
    }),
    uploadAdz: build.mutation<UploadAdzApiResponse, UploadAdzApiArg>({
      query: (queryArg) => ({
        url: `/practice-activities/upload-adz/${queryArg.id}`,
        method: "POST",
        body: queryArg.adzUpload,
        headers: { Authorization: queryArg.authorization },
      }),
    }),
    finalisePractice: build.mutation<
      FinalisePracticeApiResponse,
      FinalisePracticeApiArg
    >({
      query: (queryArg) => ({
        url: `/practice-activities/finalise/${queryArg.id}`,
        method: "POST",
        body: queryArg.appraisalQuestionnaire,
        headers: { Authorization: queryArg.authorization },
        params: { activeRole: queryArg.activeRole, version: queryArg.version },
      }),
    }),
    confirmQuestionnaire: build.mutation<
      ConfirmQuestionnaireApiResponse,
      ConfirmQuestionnaireApiArg
    >({
      query: (queryArg) => ({
        url: `/practice-activities/confirm-questionnaire/${queryArg.id}`,
        method: "POST",
        body: queryArg.appraisalQuestionnaire,
        headers: { Authorization: queryArg.authorization },
      }),
    }),
    getQuestionnaire: build.query<
      GetQuestionnaireApiResponse,
      GetQuestionnaireApiArg
    >({
      query: (queryArg) => ({
        url: `/practice-activities/confirm-questionnaire/${queryArg.id}`,
        headers: { Authorization: queryArg.authorization },
      }),
    }),
    requestContact: build.mutation<
      RequestContactApiResponse,
      RequestContactApiArg
    >({
      query: (queryArg) => ({
        url: `/practice-activities/contact-request/${queryArg.idPractice}`,
        method: "POST",
        headers: { Authorization: queryArg.authorization },
      }),
    }),
    notifyNoContact: build.mutation<
      NotifyNoContactApiResponse,
      NotifyNoContactApiArg
    >({
      query: (queryArg) => ({
        url: `/practice-activities/no-contact-notification/${queryArg.idPractice}`,
        method: "POST",
        headers: { Authorization: queryArg.authorization },
      }),
    }),
    getRejectionReasonList: build.query<
      GetRejectionReasonListApiResponse,
      GetRejectionReasonListApiArg
    >({
      query: (queryArg) => ({
        url: `/practice-activities/rejection-reason-list/`,
        headers: { Authorization: queryArg.authorization },
      }),
    }),
    rejectPractice: build.mutation<
      RejectPracticeApiResponse,
      RejectPracticeApiArg
    >({
      query: (queryArg) => ({
        url: `/practice-activities/reject/${queryArg.id}`,
        method: "POST",
        body: queryArg.rejectionRequest,
        headers: { Authorization: queryArg.authorization },
      }),
    }),
    sendRejectedFeedback: build.mutation<
      SendRejectedFeedbackApiResponse,
      SendRejectedFeedbackApiArg
    >({
      query: (queryArg) => ({
        url: `/practice-activities/rejected-feedback/${queryArg.id}`,
        method: "PUT",
        headers: { Authorization: queryArg.authorization },
        params: { activeRole: queryArg.activeRole },
      }),
    }),
    getRemindersSupplierNetwork: build.query<
      GetRemindersSupplierNetworkApiResponse,
      GetRemindersSupplierNetworkApiArg
    >({
      query: (queryArg) => ({
        url: `/reminders-supplier-network`,
        headers: { Authorization: queryArg.authorization },
      }),
    }),
    getPracticesFromAssignmentMapByAppraiser: build.query<
      GetPracticesFromAssignmentMapByAppraiserApiResponse,
      GetPracticesFromAssignmentMapByAppraiserApiArg
    >({
      query: (queryArg) => ({
        url: `/assignment-map/appraisers/${queryArg.id}/practices`,
        headers: { Authorization: queryArg.authorization },
        params: { tenants: queryArg.tenants, idZone: queryArg.idZone },
      }),
    }),
    getAppraisersFromAssignmentMapByPractice: build.query<
      GetAppraisersFromAssignmentMapByPracticeApiResponse,
      GetAppraisersFromAssignmentMapByPracticeApiArg
    >({
      query: (queryArg) => ({
        url: `/assignment-map/practices/${queryArg.id}/appraisers`,
        headers: { Authorization: queryArg.authorization },
      }),
    }),
    getAssignmentMap: build.query<
      GetAssignmentMapApiResponse,
      GetAssignmentMapApiArg
    >({
      query: (queryArg) => ({
        url: `/practices/assignment-map`,
        headers: { Authorization: queryArg.authorization },
        params: {
          practiceStatus: queryArg.practiceStatus,
          isUrgent: queryArg.isUrgent,
          tenants: queryArg.tenants,
          appraiserType: queryArg.appraiserType,
          saturationCluster: queryArg.saturationCluster,
        },
      }),
    }),
    assignPractices: build.mutation<
      AssignPracticesApiResponse,
      AssignPracticesApiArg
    >({
      query: (queryArg) => ({
        url: `/practices/bulk-assignment`,
        method: "POST",
        body: queryArg.bulkAssignmentData,
        headers: { Authorization: queryArg.authorization },
      }),
    }),
    getCoverageLevelSettings: build.query<
      GetCoverageLevelSettingsApiResponse,
      GetCoverageLevelSettingsApiArg
    >({
      query: (queryArg) => ({
        url: `/practices/coverage-level-settings`,
        headers: { Authorization: queryArg.authorization },
      }),
    }),
    setCoverageLevelSettings: build.mutation<
      SetCoverageLevelSettingsApiResponse,
      SetCoverageLevelSettingsApiArg
    >({
      query: (queryArg) => ({
        url: `/practices/coverage-level-settings`,
        method: "POST",
        body: queryArg.coverageLevelSettings,
        headers: { Authorization: queryArg.authorization },
      }),
    }),
    saveAppraiserCurrentLocation: build.mutation<
      SaveAppraiserCurrentLocationApiResponse,
      SaveAppraiserCurrentLocationApiArg
    >({
      query: (queryArg) => ({
        url: `/appraisers/currentLocation`,
        method: "POST",
        body: queryArg.geographicalLocation,
        headers: { Authorization: queryArg.authorization },
      }),
    }),
    getPracticeStatusTypes: build.query<
      GetPracticeStatusTypesApiResponse,
      GetPracticeStatusTypesApiArg
    >({
      query: (queryArg) => ({
        url: `/practice-commons/practice-status-types`,
        headers: { Authorization: queryArg.authorization },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as apiCrud };
export type AdvancedSearchApiResponse =
  /** status 200 A JSON array representing the search results */
    | {
        subject?: SubjectMainData;
        policies?: PolicyMainData[];
      }[]
    | /** status 204 The request was correct but the item does not exists */ {
        message?: string;
      };
export type AdvancedSearchApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Search for a generic term */
  term?: string;
  subjectType?: string;
  /** Natural person name */
  name?: string;
  /** Natural person lastname */
  lastname?: string;
  /** Natural person fiscal code */
  fiscalCode?: string;
  /** Phone number */
  phone?: string;
  /** Email */
  email?: string;
  /** Giuridical person business name */
  businessName?: string;
  /** Giuridical person vat number */
  pIva?: string;
  /** Vehicle plate number */
  plate?: string;
  /** Number of a policy */
  policyNumber?: string;
  /** Insurance company code */
  insurance?: string;
  /** Number of a claim */
  claimNumber?: string;
};
export type CreatePolicyApiResponse =
  /** status 201 The new item is created */ {
    id?: number;
    message?: string;
  };
export type CreatePolicyApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Numeric Id of the resource */
  id: number;
  /** new policy data */
  policy: Policy;
};
export type CreateClaimApiResponse =
  /** status 200 A JSON representing the claim data */ Claim;
export type CreateClaimApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** New claim data */
  claim: Claim;
};
export type ReadClaimApiResponse =
  /** status 200 A JSON representing the claim data */
    | {
        claim?: Claim;
        policy?: PolicyMainData;
      }
    | /** status 204 The request was correct but the item does not exists */ {
        message?: string;
      };
export type ReadClaimApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Numeric Id of the resource */
  id: number;
};
export type UpdateClaimApiResponse = /** status 200 undefined */
  | undefined
  | /** status 204 The request was correct but the item does not exists */ {
      message?: string;
    };
export type UpdateClaimApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Numeric Id of the resource */
  id: number;
  /** Update a claim data */
  claim: Claim;
};
export type DeleteClaimApiResponse = /** status 200 The item is deleted */ {
  id?: number;
  message?: string;
};
export type DeleteClaimApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Numeric Id of the resource */
  id: number;
};
export type GetPracticesSummaryApiResponse =
  /** status 200 A JSON representing the attachments */
    | DashboardSummary
    | /** status 204 The request was correct but the item does not exists */ {
        message?: string;
      };
export type GetPracticesSummaryApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Numeric Id of the resource */
  idTenant: number;
};
export type UpdatePracticesSummaryApiResponse = unknown;
export type UpdatePracticesSummaryApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Numeric Id of the resource */
  idTenant: number;
  dashboardSummary: DashboardSummary;
};
export type GetPracticesStatusApiResponse =
  /** status 200 A JSON representing the attachments */
    | PracticesProcessingStatus
    | /** status 204 The request was correct but the item does not exists */ {
        message?: string;
      };
export type GetPracticesStatusApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** list of practices' ids */
  practices: number[];
  /** the active role selected by the user */
  activeRole: string;
};
export type UpdatePracticesStatusApiResponse = unknown;
export type UpdatePracticesStatusApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  practicesProcessingStatus: PracticesProcessingStatus;
};
export type GetPracticesD2SummaryByTenantApiResponse =
  /** status 200 A JSON representing the attachments */
    | DashboardD2Summary
    | /** status 204 The request was correct but the item does not exists */ {
        message?: string;
      };
export type GetPracticesD2SummaryByTenantApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Numeric Id of the resource */
  idTenant: number;
  /** if true marks an assigned practice as a documental one */
  isDocumental?: boolean;
  /** whether a practice is assigned to an internal or external expert (appraiser) */
  appraiserType?: string;
  /** whether has been scheduled an appointment for the evaluation task */
  hasAppointment?: boolean;
  /** status id of the practices' list to be filtered */
  practiceStatus?: string;
  /** aggregation status of the practices to be filtered */
  practiceStatusAggr?: string;
};
export type UpdateD2PracticesSummaryApiResponse = unknown;
export type UpdateD2PracticesSummaryApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Numeric Id of the resource */
  idTenant: number;
  dashboardD2Summary: DashboardD2Summary;
};
export type GetPracticesE2SummaryApiResponse =
  /** status 200 A JSON representing the attachments */
    | DashboardE2Summary
    | /** status 204 The request was correct but the item does not exists */ {
        message?: string;
      };
export type GetPracticesE2SummaryApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Numeric Id of the resource */
  idTenant: number;
};
export type UpdatePracticesE2SummaryApiResponse = unknown;
export type UpdatePracticesE2SummaryApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Numeric Id of the resource */
  idTenant: number;
  dashboardE2Summary: DashboardE2Summary;
};
export type GetF1PracticesSummaryByTenantApiResponse =
  /** status 200 a JSON representing Customer's Dashboard (appraisal) */
    | DashboardF1Summary
    | /** status 204 The request was correct but the item does not exists */ {
        message?: string;
      };
export type GetF1PracticesSummaryByTenantApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Numeric Id of the resource */
  idTenant: number;
};
export type FakeUpdateF1PracticesSummaryByTenantApiResponse = unknown;
export type FakeUpdateF1PracticesSummaryByTenantApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Numeric Id of the resource */
  idTenant: number;
  dashboardF1Summary: DashboardF1Summary;
};
export type GetE4PracticesSummaryByTenantApiResponse =
  /** status 200 a JSON representing Customer's Dashboard (appraisal) */
    | DashboardE4Summary
    | /** status 204 The request was correct but the item does not exists */ {
        message?: string;
      };
export type GetE4PracticesSummaryByTenantApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Numeric Id of the resource */
  idTenant: number;
};
export type FakeUpdateE4PracticesSummaryByTenantApiResponse = unknown;
export type FakeUpdateE4PracticesSummaryByTenantApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Numeric Id of the resource */
  idTenant: number;
  dashboardE4Summary: DashboardE4Summary;
};
export type GetE1PracticesSummaryByTenantApiResponse =
  /** status 200 a JSON representing Customer's Dashboard (appraisal) */
    | DashboardE1Summary
    | /** status 204 The request was correct but the item does not exists */ {
        message?: string;
      };
export type GetE1PracticesSummaryByTenantApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Numeric Id of the resource */
  idTenant: number;
};
export type FakeUpdateE1PracticesSummaryByTenantApiResponse = unknown;
export type FakeUpdateE1PracticesSummaryByTenantApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Numeric Id of the resource */
  idTenant: number;
  dashboardE1Summary: DashboardE1Summary;
};
export type GetPracticeDetailApiResponse =
  /** status 200 A JSON representing the attachments */
    | ReadOnlyPractice
    | /** status 204 The request was correct but the item does not exists */ {
        message?: string;
      };
export type GetPracticeDetailApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Numeric Id of the resource */
  id: number;
  /** the active role selected by the user */
  activeRole: string;
};
export type UpdatePracticeDetailApiResponse =
  /** status 200 A JSON representing the attachments */
    | ReadOnlyPractice
    | /** status 204 The request was correct but the item does not exists */ {
        message?: string;
      };
export type UpdatePracticeDetailApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Numeric Id of the resource */
  id: number;
  /** the active role selected by the user */
  activeRole: string;
  /** A JSON representing the practice data to update */
  writeOnlyPractice: WriteOnlyPractice;
};
export type PracticeBlockApiResponse =
  /** status 200 A JSON representing the attachments */ ItemUpdated;
export type PracticeBlockApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Numeric Id of the resource */
  id: number;
};
export type PracticeUnblockApiResponse =
  /** status 200 A JSON representing the attachments */ ItemUpdated;
export type PracticeUnblockApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Numeric Id of the resource */
  id: number;
};
export type GetUserCommunicationsListApiResponse =
  /** status 200 A JSON representing the attachments */ CommunicationsList;
export type GetUserCommunicationsListApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** whether the cummunication sender is a customer or an expert (appraiser) */
  senderType: string;
  /** list of tenants by which filter the practice list */
  tenants: number[];
  /** communications' category Code */
  categoryCode?: string;
  /** communications' status Code */
  statusCode?: string;
};
export type CreateCommunicationApiResponse = unknown;
export type CreateCommunicationApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  communication: Communication;
};
export type GetAppraisersListApiResponse =
  /** status 200 A JSON representing the attachments */ {
    expertizerList?: PracticeActivityAppraiser[];
  };
export type GetAppraisersListApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Numeric Id of the practice */
  practiceId?: number;
  /** Id of the tenant */
  tenantsId?: number[];
  /** the active role selected by the user */
  activeRole: string;
};
export type UpdateAppraisersListApiResponse = unknown;
export type UpdateAppraisersListApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  practiceActivityAppraiser: PracticeActivityAppraiser;
};
export type AssignAppraiserApiResponse =
  /** status 200 A JSON representing the attachments */ ReadOnlyPractice;
export type AssignAppraiserApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** the active role selected by the user */
  activeRole: string;
  assignmentData: AssignmentData;
};
export type SuspendOrCancelPracticeApiResponse =
  /** status 200 A JSON representing the practice */ ReadOnlyPractice;
export type SuspendOrCancelPracticeApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** the active role selected by the user */
  activeRole: string;
  suspendOrCancelData: SuspendOrCancelData;
};
export type RestorePracticeApiResponse =
  /** status 200 A JSON representing the attachments */ ReadOnlyPractice;
export type RestorePracticeApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** the active role selected by the user */
  activeRole: string;
  restorePracticeData: RestorePracticeData;
};
export type CreatePracticeNoteApiResponse =
  /** status 200 A JSON representing the attachments */ {
    practiceNotes?: PracticeNote[];
  };
export type CreatePracticeNoteApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  practiceNote: PracticeNote;
};
export type UpdatePracticeNoteApiResponse =
  /** status 200 A JSON representing the attachments */ PracticeNote;
export type UpdatePracticeNoteApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  practiceNote: PracticeNote;
};
export type DeletePracticeNoteApiResponse =
  /** status 200 The item is deleted */ {
    id?: number;
    message?: string;
  };
export type DeletePracticeNoteApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Numeric Id of the resource */
  id: number;
};
export type GetPracticeNotesApiResponse =
  /** status 200 A JSON representing the attachments */ {
    practiceNotes?: PracticeNote[];
  };
export type GetPracticeNotesApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Numeric Id of the practice */
  idPractice: number;
};
export type CreatePracticeAppointmentApiResponse =
  /** status 200 A JSON representing the attachments */ ReadOnlyPractice;
export type CreatePracticeAppointmentApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** the active role selected by the user */
  activeRole: string;
  practiceAppointment: PracticeAppointment;
};
export type AcceptPracticeAssignmentApiResponse =
  /** status 200 A JSON representing the attachments */ ReadOnlyPractice;
export type AcceptPracticeAssignmentApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Numeric Id of the practice */
  idPractice: number;
};
export type SetOutOfCoverageAreaApiResponse =
  /** status 200 A JSON representing the attachments */ ReadOnlyPractice;
export type SetOutOfCoverageAreaApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Numeric Id of the practice */
  idPractice: number;
  /** the active role selected by the user */
  activeRole: string;
  outOfCoverageAppraisalSubject: OutOfCoverageAppraisalSubject;
};
export type UpdatePracticeRatesApiResponse =
  /** status 200 A JSON representing the attachments */ ReadOnlyPractice;
export type UpdatePracticeRatesApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Numeric Id of the practice */
  idPractice: number;
  /** the active role selected by the user */
  activeRole: string;
  practiceRate: PracticeRate;
};
export type UpdatePracticeFeesApiResponse =
  /** status 200 A JSON representing the attachments */ ReadOnlyPractice;
export type UpdatePracticeFeesApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Numeric Id of the practice */
  idPractice: number;
  /** the active role selected by the user */
  activeRole: string;
  practiceFee: PracticeFee;
};
export type GetDocumentTypesApiResponse =
  /** status 200 a JSON representing a list of document types and categories */
    | AppraisalDocumentTypes
    | /** status 204 The request was correct but the item does not exists */ {
        message?: string;
      };
export type GetDocumentTypesApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** the active role selected by the user */
  activeRole: string;
};
export type UploadPracticeDocumentApiResponse =
  /** status 200 a JSON desribing a set of uploaded documents */
    | PracticeDocumentsByCategory
    | /** status 204 The request was correct but the item does not exists */ {
        message?: string;
      };
export type UploadPracticeDocumentApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Numeric Id of the resource */
  id: number;
  practiceDocument: PracticeDocument;
};
export type GetIniFileApiResponse =
  /** status 200 a JSON desribing a set of uploaded documents */
    | PracticeDocument
    | /** status 204 The request was correct but the item does not exists */ {
        message?: string;
      };
export type GetIniFileApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Numeric Id of the resource */
  id: number;
};
export type GetConservativeAgreementFileApiResponse =
  /** status 200 a JSON desribing a set of uploaded documents */
    | PracticeDocument
    | /** status 204 The request was correct but the item does not exists */ {
        message?: string;
      };
export type GetConservativeAgreementFileApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Numeric Id of the resource */
  id: number;
};
export type DeleteDocumentAttachmentApiResponse =
  /** status 200 a JSON desribing a set of uploaded documents */
    | PracticeDocumentsByCategory
    | /** status 204 The request was correct but the item does not exists */ {
        message?: string;
      };
export type DeleteDocumentAttachmentApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Numeric Id of the resource */
  id: number;
  /** numeric attachment id */
  idAttachment: number;
};
export type GetAllPracticeDocumentsApiResponse =
  /** status 200 a JSON desribing a set of uploaded documents */
    | PracticeDocumentsByCategory
    | /** status 204 The request was correct but the item does not exists */ {
        message?: string;
      };
export type GetAllPracticeDocumentsApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Numeric Id of the resource */
  id: number;
};
export type GetPracticeHistoryApiResponse =
  /** status 200 a JSON describing an array of history logs */
    | PracticeHistoryList
    | /** status 204 The request was correct but the item does not exists */ {
        message?: string;
      };
export type GetPracticeHistoryApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Numeric Id of the practice */
  idPractice: number;
};
export type FakeUpdatePracticeHistoryApiResponse = unknown;
export type FakeUpdatePracticeHistoryApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Numeric Id of the practice */
  idPractice: number;
  practiceHistory: PracticeHistory;
};
export type GetAllPracticeRequestsApiResponse =
  /** status 200 a JSON representing user related requests */ PracticeRequests;
export type GetAllPracticeRequestsApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Numeric Id of the resource */
  id: number;
  /** the active role selected by the user */
  activeRole: string;
};
export type CreatePracticeRequestApiResponse =
  /** status 200 a JSON representing user related requests */ PracticeRequests;
export type CreatePracticeRequestApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Numeric Id of the resource */
  id: number;
  /** the active role selected by the user */
  activeRole: string;
  secondaryPracticeRequest: SecondaryPracticeRequest;
};
export type GetRequestListApiResponse =
  /** status 200 A JSON representing the the requests list */ RequestsList;
export type GetRequestListApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** last message (of the request) sender Type (User Profile) */
  lastMessageSenderType?: string;
  /** last message (of the request) receiver Type (User Profile) */
  lastMessageReceiverType?: string;
  /** list of tenants by which filter the practice list */
  tenants: number[];
  /** communications' category Code */
  categoryCode?: string;
  /** communications' status Code */
  statusCode?: string;
  /** the active role selected by the user */
  activeRole: string;
};
export type GetPracticeRequestApiResponse =
  /** status 200 a JSON representing user related request */ SecondaryPracticeRequest;
export type GetPracticeRequestApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Numeric Id of the resource */
  id: number;
  /** the active role selected by the user */
  activeRole: string;
};
export type CloseRequestApiResponse = unknown;
export type CloseRequestApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Numeric Id of the resource */
  id: number;
  /** the active role selected by the user */
  activeRole: string;
  requestMessage: RequestMessage;
};
export type ForwardRequestApiResponse = unknown;
export type ForwardRequestApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Numeric Id of the resource */
  id: number;
  /** the active role selected by the user */
  activeRole: string;
  requestMessage: RequestMessage;
};
export type ReplyToRequestMessageApiResponse =
  /** status 200 a JSON representing user related request */ SecondaryPracticeRequest;
export type ReplyToRequestMessageApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Numeric Id of the resource */
  id: number;
  /** the active role selected by the user */
  activeRole: string;
  requestMessage: RequestMessage;
};
export type ConfirmRequestClosureCheckApiResponse =
  /** status 200 a JSON representing user related request */ SecondaryPracticeRequest;
export type ConfirmRequestClosureCheckApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Numeric Id of the resource */
  id: number;
  /** the active role selected by the user */
  activeRole: string;
};
export type GetAllRequestCategoryByUserRoleApiResponse =
  /** status 200 a JSON containing categories available for user's role */ RequestCategories;
export type GetAllRequestCategoryByUserRoleApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** the active role selected by the user */
  activeRole: string;
};
export type SearchPracticesBoApiResponse =
  /** status 200 A JSON array representing the search results */
    | SearchResponseBo
    | /** status 204 The request was correct but the item does not exists */ {
        message?: string;
      };
export type SearchPracticesBoApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** the active role selected by the user */
  activeRole: string;
  searchPracticesParams: SearchPracticesParams;
};
export type SearchPracticesAppraiserApiResponse =
  /** status 200 A JSON array representing the search results */
    | SearchResponseAppraiser
    | /** status 204 The request was correct but the item does not exists */ {
        message?: string;
      };
export type SearchPracticesAppraiserApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** the active role selected by the user */
  activeRole: string;
  searchPracticesParams: SearchPracticesParams;
};
export type UpdateAppraisalLocationApiResponse =
  /** status 200 A JSON object representing the practice data */
    | ReadOnlyPractice
    | /** status 204 The request was correct but the item does not exists */ {
        message?: string;
      };
export type UpdateAppraisalLocationApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Numeric Id of the practice */
  idPractice: number;
  /** the active role selected by the user */
  activeRole: string;
  appraisalSubject: AppraisalSubject;
};
export type GenerateCongruityReportApiResponse =
  /** status 200 A JSON object representing the practice data */
    | StringValue
    | /** status 204 The request was correct but the item does not exists */ {
        message?: string;
      };
export type GenerateCongruityReportApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Numeric Id of the resource */
  id: number;
  /** used to finalise the practice post closure */
  version?: number;
  congruityReport: CongruityReport;
};
export type UploadAdzApiResponse =
  /** status 200 A JSON object representing the practice data */
    | AppraisalDocProcessingOutput
    | /** status 204 The request was correct but the item does not exists */ {
        message?: string;
      };
export type UploadAdzApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Numeric Id of the resource */
  id: number;
  adzUpload: AdzUpload;
};
export type FinalisePracticeApiResponse =
  /** status 200 practice successfully finalised */
    | AppraisalFinalizeResponse
    | /** status 204 The request was correct but the item does not exists */ {
        message?: string;
      };
export type FinalisePracticeApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Numeric Id of the resource */
  id: number;
  /** the active role selected by the user */
  activeRole: string;
  /** used to finalise the practice post closure */
  version?: number;
  appraisalQuestionnaire: AppraisalQuestionnaire;
};
export type ConfirmQuestionnaireApiResponse =
  /** status 200 practice successfully finalised */
    | undefined
    | /** status 204 The request was correct but the item does not exists */ {
        message?: string;
      };
export type ConfirmQuestionnaireApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Numeric Id of the resource */
  id: number;
  appraisalQuestionnaire: AppraisalQuestionnaire;
};
export type GetQuestionnaireApiResponse =
  /** status 200 A JSON representing the attachments */
    | AppraisalQuestionnaire
    | /** status 204 The request was correct but the item does not exists */ {
        message?: string;
      };
export type GetQuestionnaireApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Numeric Id of the resource */
  id: number;
};
export type RequestContactApiResponse =
  /** status 200 practice successfully finalised */
    | string
    | /** status 204 The request was correct but the item does not exists */ {
        message?: string;
      };
export type RequestContactApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Numeric Id of the practice */
  idPractice: number;
};
export type NotifyNoContactApiResponse =
  /** status 200 practice successfully finalised */
    | string
    | /** status 204 The request was correct but the item does not exists */ {
        message?: string;
      };
export type NotifyNoContactApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Numeric Id of the practice */
  idPractice: number;
};
export type GetRejectionReasonListApiResponse =
  /** status 200 a JSON representing rejection reason list data */
    | RejectionReasonList
    | /** status 204 The request was correct but the item does not exists */ {
        message?: string;
      };
export type GetRejectionReasonListApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
};
export type RejectPracticeApiResponse =
  /** status 200 a JSON representing practice data */
    | Blob
    | /** status 204 The request was correct but the item does not exists */ {
        message?: string;
      };
export type RejectPracticeApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Numeric Id of the resource */
  id: number;
  rejectionRequest: RejectionRequest;
};
export type SendRejectedFeedbackApiResponse =
  /** status 200 a JSON representing practice data */
    | Blob
    | /** status 204 The request was correct but the item does not exists */ {
        message?: string;
      };
export type SendRejectedFeedbackApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Numeric Id of the resource */
  id: number;
  /** the active role selected by the user */
  activeRole: string;
};
export type GetRemindersSupplierNetworkApiResponse =
  /** status 200 A JSON representing the reminders data of the supplier network */
    | ReminderSupplierNetwork
    | /** status 204 The request was correct but the item does not exists */ {
        message?: string;
      };
export type GetRemindersSupplierNetworkApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
};
export type GetPracticesFromAssignmentMapByAppraiserApiResponse =
  /** status 200 A JSON representing data for appraiser's related practices on map */
    | PracticesByAppraiserMapData
    | /** status 204 The request was correct but the item does not exists */ {
        message?: string;
      };
export type GetPracticesFromAssignmentMapByAppraiserApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Numeric Id of the resource */
  id: number;
  /** list of tenants by which filter the practice list */
  tenants: number[];
  /** Numeric Id of the resource */
  idZone?: number;
};
export type GetAppraisersFromAssignmentMapByPracticeApiResponse =
  /** status 200 A JSON representing data for practice's related appraisers on map */
    | AppraisersByPracticeMapData
    | /** status 204 The request was correct but the item does not exists */ {
        message?: string;
      };
export type GetAppraisersFromAssignmentMapByPracticeApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Numeric Id of the resource */
  id: number;
};
export type GetAssignmentMapApiResponse =
  /** status 200 A JSON representing data for appraisers and practices */
    | AssignmentMapData
    | /** status 204 The request was correct but the item does not exists */ {
        message?: string;
      };
export type GetAssignmentMapApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** status id of the practices' list to be filtered */
  practiceStatus?: string;
  /** true if practice is urgent */
  isUrgent?: boolean;
  /** list of tenants by which filter the practice list */
  tenants: number[];
  /** whether a practice is assigned to an internal or external expert (appraiser) */
  appraiserType?: string;
  /** appraiser's saturation cluster */
  saturationCluster?: string;
};
export type AssignPracticesApiResponse =
  /** status 201 The new item is created */
    | {
        id?: number;
        message?: string;
      }
    | /** status 204 The request was correct but the item does not exists */ {
        message?: string;
      };
export type AssignPracticesApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  bulkAssignmentData: BulkAssignmentData;
};
export type GetCoverageLevelSettingsApiResponse =
  /** status 200 A JSON representing coverage level settings data */
    | CoverageLevelSettings
    | /** status 204 The request was correct but the item does not exists */ {
        message?: string;
      };
export type GetCoverageLevelSettingsApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
};
export type SetCoverageLevelSettingsApiResponse =
  /** status 200 A JSON representing coverage level settings data */
    | CoverageLevelSettings
    | /** status 204 The request was correct but the item does not exists */ {
        message?: string;
      };
export type SetCoverageLevelSettingsApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  coverageLevelSettings: CoverageLevelSettings;
};
export type SaveAppraiserCurrentLocationApiResponse =
  /** status 201 The new item is created */
    | {
        id?: number;
        message?: string;
      }
    | /** status 204 The request was correct but the item does not exists */ {
        message?: string;
      };
export type SaveAppraiserCurrentLocationApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  geographicalLocation: GeographicalLocation;
};
export type GetPracticeStatusTypesApiResponse =
  /** status 200 A JSON representing coverage level settings data */
    | string[]
    | /** status 204 The request was correct but the item does not exists */ {
        message?: string;
      };
export type GetPracticeStatusTypesApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
};
export type SubjectMainData = {
  id?: number;
  subjectType?: string;
  name?: string;
  lastname?: string;
  fiscalCode?: string;
  businessName?: string;
  giuridicalType?: string;
  pIva?: string;
} | null;
export type ClaimMainDataCreated = {
  id?: number;
  number?: string;
  occurrenceDate?: string;
  occurrenceTime?: string;
};
export type ClaimMainData = {
  created?: ClaimMainDataCreated;
  received?: ClaimMainDataCreated;
};
export type VehicleMainData = {
  id?: number;
  type?: string;
  plateNumber?: string;
  plateFormat?: string;
};
export type PolicyMainData = {
  id?: number;
  number?: string;
  effectDate?: string;
  expirationDate?: string;
  claims?: ClaimMainData[];
  vehicle?: VehicleMainData;
  insuranceCompanyCode?: string;
  owner?: SubjectMainData;
  contractor?: SubjectMainData;
};
export type ResponseError = {
  errorCode?: string;
  errorMessage?: string;
};
export type InsuredProperty = {
  id?: number;
  plateNumber?: string;
  registrationYear?: number;
  universalClass?: string;
  vehicleTypeCode?: string;
  chassisNumber?: string;
  brand?: string;
  modelName?: string;
  modelVersionCode?: string;
  fuelTypeCode?: string;
  commercialValue?: number;
  registrationDate?: string;
  purchaseDate?: string;
  vehiclePowerKw?: number;
  vehicleWeight?: number;
};
export type PolicyGuarantee = {
  id?: number;
  guaranteeCode?: string;
  issueDate?: string;
  expirationDate?: string;
  annualClaimsNumber?: number;
  maximumAmountPeople?: number;
  maximumAmountProperty?: number;
  maximumAmount?: number;
  excessAmount?: number;
  overdraftAmount?: number;
  overdraftPercentage?: number;
  chargeType?: string;
};
export type BirthDateAndPlace = {
  date?: string;
  city?: string;
  province?: string;
  country?: string;
};
export type Address = {
  id?: number;
  type?: string;
  street?: string;
  civic?: string;
  postalCode?: string;
  city?: string;
  province?: string;
  country?: string;
  latitude?: number;
  longitude?: number;
  preferred?: boolean;
  isDeleted?: boolean;
  isIncorrect?: boolean;
};
export type Contact = {
  id?: number;
  type?: string;
  value?: string;
  description?: string;
  useCase?: string;
  preferred?: boolean;
  isDeleted?: boolean;
};
export type SubjectDocument = {
  id?: number;
  documentCode?: string;
  type?: string;
  number?: string;
  issuingDate?: string;
  expirationDate?: string;
  issuingInstitution?: string;
};
export type SubjectPayment = {
  id?: number;
  type?: string;
  iban?: string | null;
  bicSwift?: string | null;
  addressSendCheck?: Address | null;
  preferred?: boolean;
};
export type Subject = {
  id?: number;
  subjectType?: string;
  name?: string;
  lastname?: string;
  gender?: string;
  fiscalCode?: string;
  birth?: BirthDateAndPlace | null;
  businessName?: string;
  giuridicalType?: string;
  registeredOffice?: Address | null;
  pIva?: string;
  contacts?: Contact[] | null;
  addresses?: Address[] | null;
  documents?: SubjectDocument[] | null;
  payments?: SubjectPayment[] | null;
};
export type PolicySubject = {
  id?: number;
  rolePolicyType?: string;
  subject?: Subject;
};
export type Policy = {
  id?: number;
  number?: string;
  insuranceCompanyCode?: string;
  branch?: string;
  effectDate?: string;
  expirationDate?: string;
  exclusiveDrive?: boolean;
  blackBoxPresence?: boolean;
  insuredProperties?: InsuredProperty[];
  guarantees?: PolicyGuarantee[];
  policySubjects?: PolicySubject[];
};
export type CardWizardData = {
  numberOfVehicles?: number;
  typeVehicle1?: string;
  typeVehicle2?: string;
  collision?: boolean;
  italianPeninsula?: boolean;
};
export type Responsibility = {
  rightWrongCode?: string;
  responsibilityPercentage?: string;
  baremsCodeA?: string;
  baremsCodeB?: string;
  forcedReason?: string;
  signatureType?: string;
};
export type DamagedPerson = {
  person?: SubjectMainData;
  woundedParts?: string[];
  note?: string;
};
export type DamagedVehicle = {
  vehicleType?: string;
  plate?: string;
  plateFormat?: string;
  collisionPoints?: string[];
  note?: string;
};
export type DamagedProperty = {
  note?: string;
};
export type Damage = {
  type?: string;
  damagedPerson?: DamagedPerson;
  damagedVehicle?: DamagedVehicle;
  damagedProperty?: DamagedProperty;
};
export type AttachmentFile = {
  id?: number;
  name?: string;
  originalName?: string;
  documentBasePath?: string;
  base64File?: string;
};
export type DamagedPartAdditionalInfo = {
  id?: number;
  type?: string;
  description?: string;
  subject?: SubjectMainData;
  subjectRole?: string;
  document?: SubjectDocument;
  documentAttachments?: AttachmentFile[];
  payment?: SubjectPayment;
  contact?: Contact;
};
export type DamagedPart = {
  number?: string;
  subject?: SubjectMainData;
  role?: string;
  damages?: Damage[];
  additionalInfo?: DamagedPartAdditionalInfo[];
};
export type Claim = {
  id?: number;
  status?: string;
  cardWizardData?: CardWizardData;
  claimsBranchCode?: string;
  number?: string;
  registrationDate?: string;
  receiptDate?: string;
  dateOfReceiptCompany?: string;
  dateOfReceiptDekra?: string;
  occurrenceDate?: string;
  occurrenceTime?: string;
  occurrencePlace?: Address;
  policeIntervention?: boolean;
  witnesses?: boolean;
  note?: string;
  companyCodeCounterpart?: string;
  ownerSubjectTypeCodeCounterpart?: string;
  ownerFirstNameCounterpart?: string;
  ownerLastNameCounterpart?: string;
  ownerBusinessNameCounterpart?: string;
  driverFirstNameCounterpart?: string;
  driverLastNameCounterpart?: string;
  plateNumberCounterpart?: string;
  responsibility?: Responsibility;
  damagedParts?: DamagedPart[];
  policyData?: PolicyMainData;
};
export type TotalByCategory = {
  category?: string;
  total?: number;
};
export type UserRequestsSummary = {
  senderType?: string;
  recipientType?: string;
  totalsByCategory?: TotalByCategory[];
};
export type TotalByStatus = {
  id?: number;
  statusCode?: string;
  totalByStatusValue?: number;
};
export type CommunicationCategory = {
  id?: number;
  categoryCode?: string;
  totalByCategory?: number;
  totalsByCategoryAndStatus?: TotalByStatus[];
};
export type CommunicationsBySenderTypeAndCategories = {
  senderType?: string;
  communicationsByCategory?: CommunicationCategory[];
};
export type PracticeData = {
  id?: number;
  priority?: number;
  idTenant?: number;
  clientName?: string;
  claimNumber?: string;
  claimsBranchCode?: string;
  claimsBranchGroup?: string;
  plateNumber?: string;
  isUrgent?: boolean;
  practiceNumber?: string;
  practiceNumberCompany?: string;
  vehicleTypeCode?: string;
  practiceDate?: string;
  expirationDate?: string;
  claimCity?: string;
  claimCountry?: string;
  claimProvince?: string;
  claimPostalCode?: string;
  appraiser?: string;
  status?: string;
  practiceStatusAggr?: string;
  isAppointment?: boolean;
  appointmentDate?: string;
  appraisalLocationAddress?: Address | null;
  isBlocked?: boolean;
  blockingUserId?: number;
  expirationDateClient?: string;
  practiceTypeCode?: string;
  expirationDateAppraiser?: string;
  priorityAppraiser?: number;
  isNegative?: boolean;
  chassisNumber?: string;
};
export type PracticeList = PracticeData[];
export type RegisteredLetterError = {
  idPractice?: number;
  practiceNumber?: string;
  fileContent?: string;
  errorDate?: string;
};
export type DashboardSummary = {
  tenantId?: number;
  clientName?: string;
  totalPractices?: number;
  totalDailyPractices?: number;
  totalAssignedPractices?: number;
  totalDiscardedPractices?: number;
  practicesToManage?: {
    total?: number;
    urgent?: {
      rca?: number;
      cvt?: number;
    };
    due?: {
      rca?: number;
      cvt?: number;
    };
    new?: {
      rca?: number;
      cvt?: number;
    };
    suspended?: {
      rca?: number;
      cvt?: number;
    };
  };
  userRequestsSummary?: UserRequestsSummary[];
  communicationSummary?: CommunicationsBySenderTypeAndCategories[];
  practices?: PracticeList;
  registeredLetterErrors?: RegisteredLetterError[];
};
export type PracticesProcessingStatus = {
  practiceId?: number;
  practiceStatusCode?: string;
  isViewed?: boolean;
  practiceAggrStatus?: string;
  blockedBy?: string;
  blockingUserId?: number;
}[];
export type PracticesInProgressSummary = {
  total?: number;
  byIsDocumentalFlag?: {
    documental?: {
      total?: number;
      assignedToInternal?: number;
      assignedToExternal?: number;
    };
    inspective?: {
      total?: number;
      assignedToInternal?: number;
      assignedToExternal?: number;
    };
  };
  byHasAppointmentFlag?: {
    yes?: number;
    no?: number;
  };
};
export type DashboardD2Summary = {
  dashboardSummary?: DashboardSummary;
  practicesInProgress?: PracticesInProgressSummary;
  practicesToBeVerified?: number;
  practicesCancelled?: number;
};
export type RcaCvtPair = {
  rca?: number;
  cvt?: number;
};
export type PracticeBreakDown = {
  appointment?: RcaCvtPair;
  notAppointment?: RcaCvtPair;
};
export type PracticesToManageE2 = {
  total?: number;
  urgent?: PracticeBreakDown;
  due?: PracticeBreakDown;
  new?: PracticeBreakDown;
  suspended?: PracticeBreakDown;
};
export type PracticeE2List = {
  id?: number;
  practiceNumber?: string;
  practiceNumberCompany?: string;
  priority?: number;
  clientName?: string;
  sxNumber?: string;
  sxTypology?: string;
  claimsBranchGroup?: string;
  practicetypecode?: string;
  plate?: string;
  model?: string;
  appraisalLocationAddress?: Address | null;
  expertDeadline?: string;
  status?: string;
  practiceStatusAggr?: string;
  isAppointment?: boolean;
  appointmentDate?: string;
  isBlocked?: boolean;
  blockingUserId?: number;
  isRejected?: boolean;
  rejectionDate?: string;
  isViewed?: boolean;
  isViewedDate?: string;
  priorityAppraiser?: number;
  isNegative?: boolean;
  chassisNumber?: string;
};
export type DashboardE2Summary = {
  tenantId?: number;
  totalRejected?: number;
  totalConfirmationOfReceipt?: number;
  totalAppointmentToBeScheduled?: number;
  appointmentsScheduled?: number;
  appointmentsScheduledToday?: number;
  concludePractice?: number;
  totalPractices?: number;
  totalRcaPractices?: number;
  totalCvtPractices?: number;
  waitingForAgreement?: number;
  practicesToManage?: PracticesToManageE2;
  totalCommunications?: number;
  userRequestsSummary?: UserRequestsSummary[];
  communicationSummary?: CommunicationsBySenderTypeAndCategories[];
  practices?: PracticeE2List[];
};
export type PracticeF1List = (PracticeE2List & {
  isUrgent?: boolean;
  returnDateTime?: string;
  appraiserName?: string;
})[];
export type DashboardF1Summary = {
  userRequestsSummary?: UserRequestsSummary[];
  communicationSummary?: CommunicationsBySenderTypeAndCategories[];
  practices?: PracticeF1List;
};
export type PracticeDataE4 = PracticeData & {
  isRejected?: boolean;
  isDocumental?: boolean;
  rejectionReasonCode?: string;
  rejectionDate?: string;
};
export type DashboardE4Summary = {
  userRequestsSummary?: UserRequestsSummary[];
  communicationSummary?: CommunicationsBySenderTypeAndCategories[];
  practiceList?: PracticeDataE4[];
  toBeVerified?: TotalByCategory[];
  negativeToBeVerified?: TotalByCategory[];
  rejectedByCategory?: TotalByCategory[];
  toBeAssigned?: TotalByCategory[];
};
export type DashboardE1Summary = DashboardE4Summary & {
  documentalByPriority?: TotalByCategory[];
};
export type AppraisalSubject = {
  id?: number;
  subjectType?: string;
  name?: string;
  lastname?: string;
  fiscalCode?: string;
  businessName?: string;
  pIva?: string;
  email?: string;
  pec?: string;
  phone?: string;
  address?: Address;
};
export type PracticeBase = {
  id?: number;
  idTenant?: number;
  clientId?: number;
  practiceNumber?: string;
  claim?: Claim;
  policy?: Policy;
  priority?: number;
  damageTypeCode?: string;
  status?: string;
  practiceNumberCompany?: string;
  practiceTypeCode?: string;
  isDocumental?: boolean;
  isAgreedAmount?: boolean;
  isCounterpart?: boolean;
  isCreditTransfert?: boolean;
  practiceDate?: string;
  expirationDate?: string;
  expirationDateSLA?: string;
  expirationDateIvass?: string;
  expirationDateClient?: string;
  returnDateTime?: string;
  firstDocumentationDate?: string;
  isClientRepair?: boolean;
  isSecondPractice?: boolean;
  isUrgent?: boolean;
  urgencyReason?: string;
  isBlocked?: boolean;
  blockingUserId?: number;
  compensationAdjusterFirstName?: string;
  compensationAdjusterLastName?: string;
  compensationAdjusterEmail?: string;
  cancellationReason?: string;
  cancellationNote?: string;
  isLawyer?: boolean;
  owner?: AppraisalSubject;
  communicationRecipient?: AppraisalSubject | null;
  appraisalLocation?: AppraisalSubject | null;
  isTransformedDocumental?: boolean;
  isHoldRequest?: boolean;
  holdRequestDate?: string;
  commercialValue?: number;
  hearingDate?: string;
  conclusionDate?: string;
};
export type PracticeActivityPractice = {
  location?: Address;
  practiceNumber?: string;
  claimNumber?: string;
  claimsBranchCode?: string;
  plateNumber?: string;
};
export type Appraiser = {
  id?: number;
  idTenant?: number;
  appraiserStatus?: string;
  practiceCountDaily?: number;
  maxPracticeCountDaily?: number;
  practiceCountMonthly?: number;
  maxPracticeCountMonthly?: number;
  automaticClosing?: boolean;
  appraisalType?: string;
  isInternal?: boolean;
  subject?: Subject;
  practices?: PracticeActivityPractice[];
};
export type PracticeAppointment = {
  id?: number;
  idPractice?: number;
  idAppraiser?: number;
  appointmentStatus?: string;
  appointmentDate?: string;
  appointmentTimeSlot?: string;
  notes?: string;
};
export type PracticeRate = {
  rate?: number;
  rateType?: string;
  penaltyPercentage?: number;
  delta?: number;
  cashPercentage?: number;
  finalRate?: number;
  baseRate?: number;
  baseRateType?: string;
  basePenaltyPercentage?: number;
  baseDelta?: number;
  baseCashPercentage?: number;
  notes?: string;
};
export type PracticeFee = {
  isActive?: boolean;
  clientName?: string;
  feeType?: string;
  basicFee?: number;
  finalFee?: number;
};
export type PracticeNote = {
  id?: number;
  idUser?: number;
  idPractice?: number;
  userName?: string;
  textNote?: string;
  dateModify?: string;
};
export type ReadOnlyPractice = PracticeBase & {
  isViewed?: boolean;
  isViewedDate?: string;
  isRejected?: boolean;
  rejectionReasonCode?: string;
  rejectionReasonNote?: string;
  notes?: string;
  practiceStatusDate?: string;
  appraiser?: Appraiser;
  relatedPractices?: PracticeList;
  appointment?: PracticeAppointment;
  rates?: PracticeRate[];
  fee?: PracticeFee | null;
  practiceNotes?: PracticeNote[];
  appraisalLocationHist?: (AppraisalSubject & {
    isActive?: boolean;
    modificationDateTime?: string;
    outOfCoverageNote?: string;
    isOutCoverage?: boolean;
  })[];
  registeredLetterErrors?: RegisteredLetterError[];
};
export type WriteOnlyPractice = PracticeBase;
export type ItemUpdated = any;
export type Communication = {
  id?: number;
  idPractice?: number;
  idTenant?: number;
  practiceNumber?: string;
  categoryCode?: string;
  statusCode?: string;
  senderType?: string;
  sender?: string;
  sendingDate?: string;
  priorityCode?: string;
  messageText?: string;
};
export type CommunicationsList = Communication[];
export type PracticeActivityAppraiser = {
  id?: number;
  subjectType?: string;
  firstName?: string;
  lastName?: string;
  businessName?: string;
  isInternal?: boolean;
  idZona?: number;
  postalCode?: string;
  city?: string;
  province?: string;
  appraiserStatus?: string;
  practiceCountDaily?: number;
  maxPracticeCountDaily?: number;
  practiceCountMonthly?: number;
  maxPracticeCountMonthly?: number;
  workload?: string;
  availability?: string;
  latitude?: number;
  longitude?: number;
  rank?: number;
};
export type AssignmentData = {
  idPractice?: number;
  idAppraiser?: number;
};
export type SuspendOrCancelData = {
  idPractice?: number;
  practiceStatus?: string;
  cancellationReason?: string;
  cancellationNote?: string;
};
export type RestorePracticeData = {
  idPractice?: number;
  isUrgent?: boolean;
  urgencyReason?: string;
};
export type OutOfCoverageAppraisalSubject = AppraisalSubject & {
  outOfCoverageNote?: string;
};
export type AppraisalDocumentTypes = {
  documentTypeCode?: string;
  documentCategoryCode?: string;
}[];
export type PracticeAttachment = {
  id?: number;
  name?: string;
  originalName?: string;
  base64File?: string;
};
export type PracticeDocument = {
  id?: number;
  documentCode?: string;
  categoryTypeCode?: string;
  name?: string;
  documentTypeCode?: string;
  attachments?: PracticeAttachment[];
  version?: number;
};
export type PracticeDocumentsByCategory = PracticeDocument[];
export type Modifydetail = {
  key?: string;
  value?: string;
};
export type PracticeHistory = {
  idPractice?: number;
  modifyType?: string;
  modifyDescription?: string;
  isFrontend?: boolean;
  modifyUser?: string;
  modifyTimestamp?: string;
  practiceStatus?: string;
  appraiser?: string;
  modifyDetailsJson?: Modifydetail[];
};
export type PracticeHistoryList = PracticeHistory[];
export type RequestMessage = {
  id?: number;
  requestId?: number;
  senderProfile?: number;
  sender?: string;
  receiverProfile?: number;
  receiver?: string;
  messageText?: string;
  messageDate?: string;
  attachedDocuments?: PracticeDocument[];
};
export type PrimaryPracticeRequest = {
  id?: number;
  isUrgent?: boolean;
  isToCheck?: boolean;
  tenantId?: number;
  practiceId?: number;
  categoryCode?: string;
  senderProfile?: number;
  sender?: string;
  senderRole?: number;
  senderId?: number;
  recipientProfile?: number;
  status?: string;
  requestObject?: string;
  sendingDate?: string;
  messages?: RequestMessage[];
};
export type SecondaryPracticeRequest = PrimaryPracticeRequest & {
  isSecondary?: boolean;
  primaryRequest?: PrimaryPracticeRequest;
};
export type PracticeRequests = SecondaryPracticeRequest[];
export type Request = {
  id?: number;
  idPractice?: number;
  idTenant?: number;
  practiceNumber?: string;
  categoryCode?: string;
  statusCode?: string;
  senderType?: string;
  sender?: string;
  sendingDate?: string;
  priorityCode?: string;
  requestObject?: string;
  messageDate?: string;
  messageText?: string;
};
export type RequestsList = Request[];
export type RequestCategories = {
  categoryCode?: string;
  recipientTypes?: string[];
}[];
export type SearchResponseBo = {
  totalResultCount?: number;
  results?: PracticeList;
};
export type SearchPracticesParams = {
  tenants?: number[];
  plates?: string[];
  claimsNumber?: string[];
  practicesNumber?: string[];
  practiceNumberCompany?: string[];
  status?: string;
  practiceDateFrom?: string;
  practiceDateTo?: string;
  returnDateFrom?: string;
  returnDateTo?: string;
  idAppraiser?: number;
  appraiserName?: string;
  agreed?: string;
  practiceTypeCode?: string;
  chassisNumber?: string[];
  appointmentDateFrom?: string;
  appointmentDateTo?: string;
  isNegative?: boolean;
};
export type SearchResponseAppraiser = {
  totalResultCount?: number;
  results?: PracticeE2List[];
};
export type StringValue = {
  value?: string;
};
export type CongruityReport = {
  filename?: string;
  textNote?: string;
};
export type DocProcessingAlert = {
  alertCode?: string;
  alertType?: string;
  alertDescription?: string;
  alertDebugInfo?: string;
};
export type AppraisalDocProcessingOutput = {
  isAutomaticChecksError?: boolean;
  isAutomaticChecksWarning?: boolean;
  isAgreementError?: boolean;
  isAgreementWarning?: boolean;
  isConservativeAgreementFile?: boolean;
  isForcedAgreement?: boolean;
  isVerbalAgreement?: boolean;
  isAccessProofFile?: boolean;
  isUneconomic?: boolean;
  agreement?: string;
  documentCode?: string;
  alerts?: DocProcessingAlert[];
};
export type AdzUpload = AttachmentFile & {
  isNegative?: boolean;
  version?: number;
};
export type AppraisalFinalizeResponse = {
  practiceStatus?: string;
};
export type DocumentFiles = {
  documentCode?: string;
  documentTypeCode?: string;
  documentBasePath?: string;
  attachmentsFiles?: AttachmentFile[];
};
export type AppraisalQuestionnaire = {
  isNegative?: boolean;
  negativeReasonCode?: string;
  accessProof?: boolean;
  isVerbalAgreement?: boolean;
  isForcedAgreement?: boolean;
  agreement?: string;
  isConsistent?: string;
  isUneconomic?: boolean;
  notes?: string;
  conclusionDate?: string;
  totalTaxableAmount?: number;
  documents?: DocumentFiles[];
};
export type RejectionReason = {
  rejectionReasonId?: number;
  rejectionReasonCode?: string;
};
export type RejectionReasonList = RejectionReason[];
export type RejectionRequest = RejectionReason & {
  rejectionReasonNote?: string;
  rejectionDate?: string;
};
export type ReminderPractice = {
  id?: number;
  priority?: number;
  priorityReason?: string;
  appraiserName?: string;
  appraiserType?: string;
  practiceNumber?: string;
  receiptDate?: string;
  assigmentAppraiserDate?: string;
  appointmentDate?: string;
  delayFromExpiring?: number;
  appraiserExpringDate?: string;
  isHoldRequest?: boolean;
  status?: string;
  appraisalType?: string;
  claimType?: string;
  plate?: string;
  clientName?: string;
  claimNumber?: string;
  clientAssignemntId?: string;
  isBlocked?: boolean;
  blockedBy?: string;
};
export type ReminderSupplierNetwork = {
  practices?: ReminderPractice[];
};
export type CoverageDistrictMapData = {
  districtCode?: string;
  saturationClusterCode?: string;
  idzone?: number;
  city?: string;
  postalCode?: string;
  isPosition?: boolean;
  latitude?: number;
  longitude?: number;
};
export type AppraiserMapData = {
  id?: number;
  name?: string;
  totalManagedPractices?: number;
  totalDailyCapacity?: number;
  monthlyResidual?: number;
  totalMonthlyCapacity?: number;
  totalScore?: number;
  address?: string;
  isInternal?: boolean;
  coverageDistricts?: CoverageDistrictMapData[];
};
export type PracticeMapData = {
  id?: number;
  practiceNumber?: string;
  practiceStatus?: string;
  practiceTypeCode?: string;
  isUrgent?: boolean;
  assignedAppraiser?: string;
  assignedAppraiserId?: number;
  receiptDate?: string;
  appraisalLocationAddress?: Address;
};
export type PracticesByAppraiserMapData = {
  appraiser?: AppraiserMapData;
  practices?: PracticeMapData[];
};
export type AppraisersByPracticeMapData = {
  practice?: PracticeMapData;
  appraisers?: AppraiserMapData[];
};
export type AssignmentMapData = {
  appraisers?: AppraiserMapData[];
  practices?: PracticeMapData[];
};
export type Assignment = {
  practiceId?: number;
  isToBeAssigned?: boolean;
};
export type BulkAssignmentData = {
  appraiserId?: number;
  assignments?: Assignment[];
};
export type CoverageLevel = {
  coverageLevelCode?: string;
  minValue?: number;
  maxValue?: number;
};
export type CoverageLevelSettings = {
  assignmentMapRange?: number;
  coverageLevels?: CoverageLevel[];
};
export type GeographicalLocation = {
  latitude?: number;
  longitude?: number;
};
export const {
  useAdvancedSearchQuery,
  useLazyAdvancedSearchQuery,
  useCreatePolicyMutation,
  useCreateClaimMutation,
  useReadClaimQuery,
  useLazyReadClaimQuery,
  useUpdateClaimMutation,
  useDeleteClaimMutation,
  useGetPracticesSummaryQuery,
  useLazyGetPracticesSummaryQuery,
  useUpdatePracticesSummaryMutation,
  useGetPracticesStatusQuery,
  useLazyGetPracticesStatusQuery,
  useUpdatePracticesStatusMutation,
  useGetPracticesD2SummaryByTenantQuery,
  useLazyGetPracticesD2SummaryByTenantQuery,
  useUpdateD2PracticesSummaryMutation,
  useGetPracticesE2SummaryQuery,
  useLazyGetPracticesE2SummaryQuery,
  useUpdatePracticesE2SummaryMutation,
  useGetF1PracticesSummaryByTenantQuery,
  useLazyGetF1PracticesSummaryByTenantQuery,
  useFakeUpdateF1PracticesSummaryByTenantMutation,
  useGetE4PracticesSummaryByTenantQuery,
  useLazyGetE4PracticesSummaryByTenantQuery,
  useFakeUpdateE4PracticesSummaryByTenantMutation,
  useGetE1PracticesSummaryByTenantQuery,
  useLazyGetE1PracticesSummaryByTenantQuery,
  useFakeUpdateE1PracticesSummaryByTenantMutation,
  useGetPracticeDetailQuery,
  useLazyGetPracticeDetailQuery,
  useUpdatePracticeDetailMutation,
  usePracticeBlockMutation,
  usePracticeUnblockMutation,
  useGetUserCommunicationsListQuery,
  useLazyGetUserCommunicationsListQuery,
  useCreateCommunicationMutation,
  useGetAppraisersListQuery,
  useLazyGetAppraisersListQuery,
  useUpdateAppraisersListMutation,
  useAssignAppraiserMutation,
  useSuspendOrCancelPracticeMutation,
  useRestorePracticeMutation,
  useCreatePracticeNoteMutation,
  useUpdatePracticeNoteMutation,
  useDeletePracticeNoteMutation,
  useGetPracticeNotesQuery,
  useLazyGetPracticeNotesQuery,
  useCreatePracticeAppointmentMutation,
  useAcceptPracticeAssignmentMutation,
  useSetOutOfCoverageAreaMutation,
  useUpdatePracticeRatesMutation,
  useUpdatePracticeFeesMutation,
  useGetDocumentTypesQuery,
  useLazyGetDocumentTypesQuery,
  useUploadPracticeDocumentMutation,
  useGetIniFileQuery,
  useLazyGetIniFileQuery,
  useGetConservativeAgreementFileQuery,
  useLazyGetConservativeAgreementFileQuery,
  useDeleteDocumentAttachmentMutation,
  useGetAllPracticeDocumentsQuery,
  useLazyGetAllPracticeDocumentsQuery,
  useGetPracticeHistoryQuery,
  useLazyGetPracticeHistoryQuery,
  useFakeUpdatePracticeHistoryMutation,
  useGetAllPracticeRequestsQuery,
  useLazyGetAllPracticeRequestsQuery,
  useCreatePracticeRequestMutation,
  useGetRequestListQuery,
  useLazyGetRequestListQuery,
  useGetPracticeRequestQuery,
  useLazyGetPracticeRequestQuery,
  useCloseRequestMutation,
  useForwardRequestMutation,
  useReplyToRequestMessageMutation,
  useConfirmRequestClosureCheckMutation,
  useGetAllRequestCategoryByUserRoleQuery,
  useLazyGetAllRequestCategoryByUserRoleQuery,
  useSearchPracticesBoMutation,
  useSearchPracticesAppraiserMutation,
  useUpdateAppraisalLocationMutation,
  useGenerateCongruityReportMutation,
  useUploadAdzMutation,
  useFinalisePracticeMutation,
  useConfirmQuestionnaireMutation,
  useGetQuestionnaireQuery,
  useLazyGetQuestionnaireQuery,
  useRequestContactMutation,
  useNotifyNoContactMutation,
  useGetRejectionReasonListQuery,
  useLazyGetRejectionReasonListQuery,
  useRejectPracticeMutation,
  useSendRejectedFeedbackMutation,
  useGetRemindersSupplierNetworkQuery,
  useLazyGetRemindersSupplierNetworkQuery,
  useGetPracticesFromAssignmentMapByAppraiserQuery,
  useLazyGetPracticesFromAssignmentMapByAppraiserQuery,
  useGetAppraisersFromAssignmentMapByPracticeQuery,
  useLazyGetAppraisersFromAssignmentMapByPracticeQuery,
  useGetAssignmentMapQuery,
  useLazyGetAssignmentMapQuery,
  useAssignPracticesMutation,
  useGetCoverageLevelSettingsQuery,
  useLazyGetCoverageLevelSettingsQuery,
  useSetCoverageLevelSettingsMutation,
  useSaveAppraiserCurrentLocationMutation,
  useGetPracticeStatusTypesQuery,
  useLazyGetPracticeStatusTypesQuery,
} = injectedRtkApi;
