import { useEffect, useState } from "react";
import { SelectPair } from "../../types/common.types";
import { useTranslation } from "react-i18next";
import { useLazyGetPracticeStatusTypesQuery } from "../../redux/apiSpecifications/apiCrud";
import { useAuthorization } from "../../hooks/useAuthorization";
import styled from "styled-components";
import { Select } from "antd";

const AutocompletePracticeStatusWrapper = styled.div`
  flex: 1;

  .select-appraiser-label {
    text-transform: uppercase;
    font-size: 0.9em;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }
`;

const SelectStyled = styled(Select)`
  width: 100%;
  margin-bottom: 2em;
`;

interface IAutocompletePracticeStatusProps {
  // appraiser: Appraiser | undefined;
  // onChange: (name: string) => void;
  onSelect: (practiceStatusCode: string) => void;
  // onDataLoaded?: (appraisers: Appraiser[]) => void;
}

const AutocompletePracticeStatus = ({
  onSelect,
}: IAutocompletePracticeStatusProps) => {
  const { t } = useTranslation();

  const { getAuthorization } = useAuthorization();

  const [getPracticeStatusTypes] = useLazyGetPracticeStatusTypesQuery();

  const [options, setOptions] = useState<SelectPair[]>([]);

  useEffect(() => {
    (async () => {
      const response = await getPracticeStatusTypes({
        authorization: await getAuthorization(),
      });

      if (response.isSuccess) {
        const practiceStatusCodeList = response.data as string[];

        const _options = practiceStatusCodeList
          .map((code) => ({
            value: code,
            label: t(`appraisal-statuses-${code}`),
          }))
          .slice()
          .sort((a, b) => (a.label > b.label ? 1 : -1));

        setOptions([{ value: "", label: "" }, ..._options]);
      }
    })();
  }, []);

  const handleFilterSearchLabel = (input: string, option: any) => {
    return option.children[0].toLowerCase().indexOf(input.toLowerCase()) > -1;;
  };

  const handleOnSelectPracticeStatus = (index: string) => {
    const iId = parseInt(index);

    const _practiceStatus = options[iId];
    
    if (_practiceStatus) {
      onSelect(_practiceStatus.value);
    }
  };

  return (
    <AutocompletePracticeStatusWrapper>
      <div className="select-appraiser-label">
        {t("appraisals-details-tab-details-appraisals-status-label")}
      </div>

      <SelectStyled
        optionLabelProp="children"
        showSearch
        filterOption={handleFilterSearchLabel}
        onChange={(index) => handleOnSelectPracticeStatus(index as string)}
      >
        {options.map((item, i) => (
          <Select.Option key={i}>{item.label} </Select.Option>
        ))}
      </SelectStyled>
    </AutocompletePracticeStatusWrapper>
  );
};

export default AutocompletePracticeStatus;
